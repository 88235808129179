import type { Component } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { LotteryHeader, ScratchcardHeader } from '#components'
import { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

export const INFO_ROUTE_TYPES = {
  Lottery: 'lottery',
  Scratchcard: 'scratchcard',
} as const

export type InfoRouteType = ObjectValues<typeof INFO_ROUTE_TYPES>

type InfoRouteNameConfig = { Faq: string; Prizes: string; Rules: string }

export type InfoRouteMetaConfig = {
  header: Component
  param: string
  routes: InfoRouteNameConfig
  type: InfoRouteType
}

export const CUSTOM_INFO_ROUTES = {
  Faq: 'faq',
  Prizes: 'prizes',
  Rules: 'rules',
} as const

export const CUSTOM_INFO_ROUTE_NAMES_LOTTERY: InfoRouteNameConfig = {
  Faq: 'faqLottery',
  Prizes: 'prizesLottery',
  Rules: 'rulesLottery',
} as const

export const CUSTOM_INFO_ROUTE_NAMES_SCRATCHCARD: InfoRouteNameConfig = {
  Faq: 'faqScratchcard',
  Prizes: 'prizesScratchcard',
  Rules: 'rulesScratchcard',
} as const

export type CustomInfoRoute = ObjectValues<typeof CUSTOM_INFO_ROUTES>

const InfoWrapper = () => import('~/custom-pages/info/index.vue')
const InfoFaq = () => import('~/custom-pages/info/children/faq.vue')
const InfoPrizes = () => import('~/custom-pages/info/children/prizes.vue')
const InfoRules = () => import('~/custom-pages/info/children/rules.vue')

const getComponents = (routeNames: InfoRouteNameConfig) => ({
  [routeNames.Faq]: InfoFaq,
  [routeNames.Prizes]: InfoPrizes,
  [routeNames.Rules]: InfoRules,
})

const componentsLottery = getComponents(CUSTOM_INFO_ROUTE_NAMES_LOTTERY)

const componentsScratchcard = getComponents(CUSTOM_INFO_ROUTE_NAMES_SCRATCHCARD)

export const lotteryInfoRoutes: RouteRecordRaw = {
  children: [
    {
      components: componentsLottery,
      name: CUSTOM_INFO_ROUTE_NAMES_LOTTERY.Rules,
      path: CUSTOM_INFO_ROUTES.Rules,
    },
    {
      components: componentsLottery,
      name: CUSTOM_INFO_ROUTE_NAMES_LOTTERY.Prizes,
      path: CUSTOM_INFO_ROUTES.Prizes,
    },
    {
      components: componentsLottery,
      name: CUSTOM_INFO_ROUTE_NAMES_LOTTERY.Faq,
      path: CUSTOM_INFO_ROUTES.Faq,
    },
  ],
  component: InfoWrapper,
  meta: {
    config: {
      header: shallowRef(LotteryHeader),
      param: 'lotteryId',
      routes: CUSTOM_INFO_ROUTE_NAMES_LOTTERY,
      type: INFO_ROUTE_TYPES.Lottery,
    } as InfoRouteMetaConfig,
    layout: false,
    path: `/:lotteryId(${Object.values(LotteryId).join('|').toLowerCase()})/info`,
  },
  path: '/:lotteryId/info',
  redirect: (to) => ({ path: `${to.path}/${CUSTOM_INFO_ROUTES.Rules}` }),
}

export const scratchcardInfoRoutes: RouteRecordRaw = {
  children: [
    {
      components: componentsScratchcard,
      name: CUSTOM_INFO_ROUTE_NAMES_SCRATCHCARD.Rules,
      path: CUSTOM_INFO_ROUTES.Rules,
    },
    {
      components: componentsScratchcard,
      name: CUSTOM_INFO_ROUTE_NAMES_SCRATCHCARD.Prizes,
      path: CUSTOM_INFO_ROUTES.Prizes,
    },
    {
      components: componentsScratchcard,
      name: CUSTOM_INFO_ROUTE_NAMES_SCRATCHCARD.Faq,
      path: CUSTOM_INFO_ROUTES.Faq,
    },
  ],
  component: InfoWrapper,
  meta: {
    config: {
      header: shallowRef(ScratchcardHeader),
      param: 'slug',
      routes: CUSTOM_INFO_ROUTE_NAMES_SCRATCHCARD,
      type: INFO_ROUTE_TYPES.Scratchcard,
    } as InfoRouteMetaConfig,
    layout: false,
    path: '/scratchcard/:slug/info',
  },
  path: '/scratchcard/:slug/info',
  redirect: (to) => ({ path: `${to.path}/${CUSTOM_INFO_ROUTES.Rules}` }),
}
