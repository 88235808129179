<template>
  <Modal
    :cancel-is-ghost="false"
    confirm-is-ghost
    is-visible
    @cancel="emit('cancel')"
    @confirm="emit('confirm')"
  >
    <template #headline>
      {{ $t('externallink.modal.headline') }}
    </template>
    <p>{{ $t('externallink.modal.description') }}</p>
    <Illustration class="mt-lg" name="pageexit-info" />
    <strong>{{ $t('externallink.modal.areyousure') }}</strong>
    <template #confirm>
      {{ $t('externallink.modal.confirm') }}
    </template>

    <template #cancel>
      {{ $t('externallink.modal.cancel') }}
    </template>
  </Modal>
</template>

<script lang="ts" setup>
type HyperLinkEmit = {
  (e: 'cancel'): void
  (e: 'confirm'): void
}

const emit = defineEmits<HyperLinkEmit>()
</script>
