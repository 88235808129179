import type { RouterConfig } from '@nuxt/schema'

import cmsRoutes from '~/app/routes.json'
import {
  lotteryInfoRoutes,
  scratchcardInfoRoutes,
} from '~/constants/infoRoutes'
import { myOrdersRoutes } from '~/constants/myOrdersRoutes'
import { playNowRoutes } from '~/constants/playNowRoutes'

export default <RouterConfig>{
  routes: (routes) => [
    ...routes,
    ...((cmsRoutes as Record<string, string[]>)[
      useLicensedTerritory({ raw: true })
    ]?.map((route) => ({
      component: () => import('~/custom-pages/cms.vue'),
      meta: {
        footer: false,
        layout: false,
        navbar: false,
      },
      path: `/${route}`,
    })) ?? []),
    lotteryInfoRoutes,
    scratchcardInfoRoutes,
    myOrdersRoutes,
    playNowRoutes,
  ],
}
