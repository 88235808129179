import { PersistedStateCheckout } from '~/features/Checkout/apollo/cache'
import { PersistedStateLottery } from '~/features/Lottery/apollo/cache'
import { PersistedStateScratchcard } from '~/features/Scratchcard/apollo/cache'
import { PersistedStateVerificationData } from '~/features/Verification/apollo/cache'

export const PersistedState = {
  ...PersistedStateCheckout,
  ...PersistedStateLottery,
  ...PersistedStateScratchcard,
  ...PersistedStateVerificationData,
} as const

export type PersistedStates = ObjectValues<typeof PersistedState>
