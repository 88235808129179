import { cache } from '~/apollo/local/cache'
import { PersistedState } from '~/apollo/local/types'
import { MIN_SELECTED_QUANTITY } from '~/features/Scratchcard/apollo/cache'

export const useScratchcardGame = (_slug?: string) => {
  const licensedTerritory = useLicensedTerritory({ raw: true })
  const slug = computed(() => {
    const { params, query } = useRoute()
    return _slug ?? params.slug ?? query.p ?? ''
  })

  const queryResult = useScratchcardGameQuery({
    licensedTerritory,
    slug: slug.value,
  })

  const currentPrice = computed(() => game.value?.currentPrice)
  const game = computed(() => queryResult.result.value?.scratchcardGame)
  const isLoading = computed(() => queryResult.loading.value)
  const maxQuantity = computed(() => game.value?.maxQuantity)
  const minQuantity = computed(() => game.value?.minQuantity)
  const name = computed(() => game.value?.name)
  const selectedQuantity = computed(
    () => game.value?.selectedQuantity ?? MIN_SELECTED_QUANTITY,
  )
  const prizeTiers = computed(() => game.value?.prizeTiers)
  const jackpot = computed(() =>
    prizeTiers.value?.find((prizeTier) => prizeTier.tier === 1),
  )
  const visualParameters = computed(() => game.value?.visualParameters)

  const setSelectedQuantity = (value: number) => {
    cache.updateCache(PersistedState.ScratchcardSelectedQuantity, value, {
      licensedTerritory,
      slug: slug.value,
    })
  }

  return {
    currentPrice,
    game,
    isLoading,
    jackpot,
    maxQuantity,
    minQuantity,
    name,
    prizeTiers,
    selectedQuantity,
    setSelectedQuantity,
    visualParameters,
  }
}
