import routerOptions0 from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/lottocom/shop-frontend/frontend/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}