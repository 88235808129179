<template>
  <ul v-if="visiblePaymentIcons.length" class="flex wrapper" :class="gapClass">
    <li
      v-for="{ title, icon } in visiblePaymentIcons"
      :key="title"
      :class="[{ bg: withBackground }, size]"
    >
      <LcImage
        :alt="title"
        class="flex items-center justify-center h-full w-full"
        :height="icon.height"
        :src="icon.url"
        :width="icon.width"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { LcImage } from '@lottocom/frontend-components'

type PaymentIconsProps = {
  filter?: string[]
  gapClass?: 'gap-md' | 'gap-xs' | 'gap-xxs'
  size?: 'large' | 'medium'
  withBackground?: boolean
}

const props = withDefaults(defineProps<PaymentIconsProps>(), {
  filter: () => [],
  gapClass: 'gap-md',
  size: 'medium',
})

const visiblePaymentIcons = usePaymentIcons(toRef(props, 'filter'))
</script>

<style lang="scss" scoped>
$height-large: 1.75rem;
$height-medium: 1.5rem;
$spacing-large: spacing('2xs');
$spacing-medium: spacing('3xs');
$border-size: 1px;

.wrapper {
  &.gap-xxs {
    gap: 0.25rem;
  }

  li {
    &.large {
      height: calc($height-large - (2 * $spacing-large) - (2 * $border-size));
    }

    &.medium {
      height: calc($height-medium - (2 * $spacing-medium) - (2 * $border-size));
    }

    &.bg {
      border: $border-size solid color('secondary300');
      border-radius: $border-radius-sm;
      background: linear-gradient(
        180deg,
        rgba(238, 247, 255, 40%) 0%,
        rgba(255, 255, 255, 40%) 100%
      );
    }

    &.bg.large {
      width: 3.375rem;
      height: $height-large;
      padding: $spacing-large;
    }

    &.bg.medium {
      width: 2.5rem;
      height: $height-medium;
      padding: $spacing-medium;
    }
  }
}
</style>
