import type { ApolloLink } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const retryOnAnyConnectionProblem = {
  retryAttempts: Infinity,
  shouldRetry: (): boolean => true,
}

export const createWsLink = (url?: string): Maybe<ApolloLink> => {
  if (!url || typeof window === 'undefined') return null

  let timedOut: NodeJS.Timeout
  const client = createClient({
    ...retryOnAnyConnectionProblem,
    keepAlive: 15_000,
    lazy: false,
    on: {
      ping: (received) => {
        if (!received) {
          timedOut = setTimeout(() => {
            // Fixes "Safari drops WebSocket connection when the user locks the screen"
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            client.terminate()
          }, 5_000)
        }
      },
      pong: (received) => {
        if (received) clearTimeout(timedOut)
      },
    },
    url,
  })

  return new GraphQLWsLink(client)
}
