<template>
  <header v-if="usedVariant !== 'no-header'" class="the-header py-lg">
    <div class="content-wrap flex justify-between px-md">
      <section>
        <HyperLink to="/">
          <img
            :alt="shopPublicName"
            :height="32"
            src="~/assets/img/brand/logo-default.svg"
            :width="208"
          />
        </HyperLink>

        <div v-if="usedVariant === 'full'" class="h-lg mt-md">
          <template v-if="hasBalance && !showBalance">
            <TheHeaderBalance />
          </template>
          <template v-else>
            <transition name="fade">
              <TheHeaderBalance
                v-show="showBalance"
                @has-balance="showIcons = false"
              />
            </transition>
            <transition name="fade" @after-leave="showBalance = true">
              <PaymentIcons v-if="showIcons && !showBalance && !hasBalance" />
            </transition>
          </template>
        </div>
      </section>

      <TheHeaderStateSwitch v-if="usedVariant !== 'logo-only'" />
    </div>
  </header>
</template>

<script lang="ts" setup>
type TheHeaderVariant = 'full' | 'logo-only' | 'no-balance' | false

type TheHeaderProps = {
  variant?: TheHeaderVariant
}

const props = withDefaults(defineProps<TheHeaderProps>(), {
  variant: undefined,
})

const hasBalance = useState(StateKey.HasBalance)
const showBalance = ref(false)
const showIcons = ref(true)

const {
  i18nConfig: { shopPublicName },
} = useRuntimeConfig().public

const usedVariant = computed(() => {
  let { header } = useRoute().meta
  if (typeof props.variant !== 'undefined') header = props.variant

  if (typeof header === 'undefined') return 'logo-only'
  if (!header) return 'no-header'
  return header
})
</script>

<style lang="scss" scoped>
.the-header {
  a {
    @include focus-state {
      &::before {
        inset: 0;
        border-radius: $border-radius-sm;
        box-shadow:
          0 0 0 0.125rem color('white'),
          0 0 0 0.3125rem color('info700');
      }
    }

    position: relative;
    display: block;
  }
}
</style>
