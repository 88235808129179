import { gt } from 'semver'

import {
  LicensedTerritories,
  type LicensedTerritory,
} from '~/constants/licensedTerritory'

export const useCustomContext = () => {
  const isNativeApp = (useState(StateKey.IsNativeApp).value as boolean) ?? false
  const { isAndroid, isChrome, isFirefox, isIos, userAgent } = useDevice()

  const allowedStatesForAndroid: LicensedTerritory[] = [
    LicensedTerritories.US_NJ,
    LicensedTerritories.US_NY,
  ]

  const isAndroidApp = computed(() => isNativeApp && isAndroid)

  const isBlockedForAndroid = computed(() => {
    const r = /Lottocom_app\/(\d{1,}.\d{1,}.\d{1,})/

    const matched: Maybe<string[]> = userAgent.match(r)

    if (!matched || matched.length < 2) return false

    const version = matched[1]

    return (
      isNativeApp &&
      isAndroid &&
      !allowedStatesForAndroid.includes(
        useLicensedTerritory({ raw: true }) as LicensedTerritory,
      ) &&
      gt(version, '1.1.5')
    )
  })

  const isChromeIPhone = computed(() => isIos && isChrome)

  const isFirefoxAndroid = computed(() => isAndroid && isFirefox)

  return {
    isAndroidApp,
    isBlockedForAndroid,
    isChromeIPhone,
    isFirefoxAndroid,
    isNativeApp,
  }
}
