import type { PublicRuntimeConfig } from 'nuxt/schema'

export const FEATURE_FLAGS: Record<string, keyof PublicRuntimeConfig> = {
  addFunds: 'addFundsEnabled',
  csp: 'cspEnabled',
  scratchcards: 'scratchcardsEnabled',
  sentry: 'sentry.enabled',
  venmoBypass: 'venmoNativeAppBypassEnabled',
} as const

export type FeatureFlag = ObjectValues<typeof FEATURE_FLAGS>

export const useIsFeatureFlagEnabled = (name: FeatureFlag) => {
  const featureFlag = useRuntimeConfig().public[name]
  return String(featureFlag) === 'true'
}
