export const FAQ_COLLECTION_TYPES = {
  PrizesAndWinnings: 'faqPrizesAndWinnings',
  TicketsAndDrawings: 'faqTicketsAndDrawings',
  TopQuestions: 'faqTopQuestions',
} as const

type FaqCollectionType = ObjectValues<typeof FAQ_COLLECTION_TYPES>

export const getFaqCollectionType = (typename: string): FaqCollectionType => {
  switch (typename) {
    case 'FaqPrizesAndWinningsCollection':
    case 'FaqScratchcardsPrizesAndWinningsCollection':
      return FAQ_COLLECTION_TYPES.PrizesAndWinnings
    case 'FaqTopQuestionsCollection':
    case 'FaqScratchcardsTopQuestionsCollection':
      return FAQ_COLLECTION_TYPES.TopQuestions
    case 'FaqTicketsAndDrawingsCollection':
      return FAQ_COLLECTION_TYPES.TicketsAndDrawings
    default:
      return FAQ_COLLECTION_TYPES.PrizesAndWinnings
  }
}

export enum ContentType {
  Prizes = 'prizes',
  Rules = 'rules',
}
