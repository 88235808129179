<template>
  <div>
    <TheToaster />
    <NuxtLayout>
      <NuxtPwaManifest />
      <NuxtPage v-if="!isBlockedForAndroid" />

      <Modal
        v-else
        :is-visible="isBlockedForAndroid"
        @cancel="onAndroidDisclaimerCancel"
        @confirm="onAndroidDisclaimerConfirm"
      >
        <template #headline>
          {{ $t('restriction.android.title') }}
        </template>
        <p>
          {{ $t('restriction.android.text') }}
        </p>
        <template #confirm>
          {{ $t('restriction.android.cta.primary') }}
        </template>
        <template #cancel>
          {{ $t('restriction.android.cta.secondary') }}
        </template>
      </Modal>
    </NuxtLayout>
    <div id="lc-fly-outs" />
    <div id="lc-modals" />
  </div>
</template>

<script lang="ts" setup>
import broadcasterInstance, { BroadcastMessage } from '~/utils/broadcaster'

const isNativeApp = useState(StateKey.IsNativeApp)

const { isBlockedForAndroid } = useCustomContext()
const { locale, t } = useI18n()
const { clearScratchcards, clearSingleOrders, clearSubscriptions } =
  useMyOrdersCache()

useHead({
  htmlAttrs: {
    lang: locale.value,
  },
})

broadcasterInstance.on(
  BroadcastMessage.ClearScratchcardOrders,
  clearScratchcards,
)

broadcasterInstance.on(BroadcastMessage.ClearSingleOrders, clearSingleOrders)

broadcasterInstance.on(
  BroadcastMessage.ClearSubscriptionOrders,
  clearSubscriptions,
)

broadcasterInstance.on(BroadcastMessage.ContextChange, () => {
  window.location.reload()
})

const visitUrl = (url: string) => {
  if (import.meta.server) return
  try {
    window.location.href = new URL(url).toString()
  } catch (_) {}
}

const onAndroidDisclaimerCancel = () => {
  visitUrl(t('restriction.android.url.secondary'))
}

const onAndroidDisclaimerConfirm = () => {
  visitUrl(`${t('restriction.android.url.primary')}/?leaveApp=true`)
}

onBeforeMount(() => {
  Object.defineProperty(window, 'isAppWrapper', {
    configurable: true,
    get: () => isNativeApp.value,
    set: (newValue) => (isNativeApp.value = newValue === true),
  })
})
</script>
