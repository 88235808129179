export const NONCE = 'nonce'

export const useSafeNonce = () => {
  const initNonce = () => {
    if (import.meta.dev) {
      return NONCE
    }

    if (import.meta.server) {
      return useNuxtApp().ssrContext?.event.context.security.nonce ?? NONCE
    }

    if (import.meta.client) {
      return window.__NONCE__ ?? NONCE
    }

    return NONCE
  }

  return String(useState(StateKey.Nonce, initNonce).value)
}
