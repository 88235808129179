export const ORDER_TYPES = {
  Scratchcard: 'scratchcard',
  SingleOrder: 'single_order',
  Subscriptions: 'subscription',
} as const

export const useOrderType = () => {
  const getLotteryOrderType = async (orderId: string) => {
    try {
      const response = await useCheckOrderTypeLazyQuery({
        orderId,
      }).load()

      if (!response) return false

      return response.ticketDetails.subscriptionInfo?.code
        ? ORDER_TYPES.Subscriptions
        : ORDER_TYPES.SingleOrder
    } catch (error) {
      return false
    }
  }

  const isScratchcardOrderType = async (orderId: string) => {
    try {
      const response = await useCheckScratchcardOrderLazyQuery({
        orderId,
      }).load()

      return !!response
    } catch (error) {
      return false
    }
  }

  const getOrderType = async (orderId: string) => {
    const lotteryOrderType = await getLotteryOrderType(orderId)
    if (lotteryOrderType) return lotteryOrderType

    const isScratchcardOrder = await isScratchcardOrderType(orderId)
    if (isScratchcardOrder) return ORDER_TYPES.Scratchcard

    return null
  }

  return { getOrderType }
}
