import {
  LicensedTerritories,
  type LicensedTerritory,
} from '~/constants/licensedTerritory'
import { CUSTOM_MY_ORDERS_ROUTES } from '~/constants/myOrdersRoutes'
export type MyOrdersTabData = { path: string }

const WITHOUT_SUBSCRIPTION: LicensedTerritory[] = [LicensedTerritories.US_NY]

export const useMyOrders = async () => {
  const { t } = useNuxtApp().$i18n
  const licensedTerritory = useLicensedTerritory({
    raw: true,
  }) as LicensedTerritory

  const isScratchcardsEnabled = useIsScratchcardEnabled()

  const response = await useOrderedTypeLazyQuery({
    fetchPolicy: 'network-only',
  }).load()

  const result = computed(() => (response ? response.orderedType : null))

  const activeScratchcardsTab = computed(
    () => isScratchcardsEnabled || !!result.value?.scratchcard,
  )

  const activeSubscriptionsTab = computed(
    () =>
      !WITHOUT_SUBSCRIPTION.includes(licensedTerritory) ||
      !!result.value?.subscription,
  )

  const { activeTabs } = useTabs<MyOrdersTabData>([
    {
      data: { path: CUSTOM_MY_ORDERS_ROUTES.SingleOrders.path },
      icon: 'orders',
      name: CUSTOM_MY_ORDERS_ROUTES.SingleOrders.name,
      title: t('myorders.tabs.singleorder.title'),
    },
    {
      active: activeSubscriptionsTab,
      data: { path: CUSTOM_MY_ORDERS_ROUTES.Subscriptions.path },
      icon: 'subscription',
      name: CUSTOM_MY_ORDERS_ROUTES.Subscriptions.name,
      title: t('myorders.tabs.subscription.title'),
    },
    {
      active: activeScratchcardsTab,
      data: { path: CUSTOM_MY_ORDERS_ROUTES.Scratchcards.path },
      icon: 'scratchcard',
      name: CUSTOM_MY_ORDERS_ROUTES.Scratchcards.name,
      title: t('myorders.tabs.scratchcard.title'),
    },
  ])

  return { activeTabs }
}
