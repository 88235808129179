import {
  type CheckoutQuery,
  CheckoutState,
  type PayWithTopUp,
  type ScratchcardProduct,
  type StoredPaymentInstrument,
} from '~/@types/generated/backend/graphql-schema-types'
import { REPEAT_NOT_FULFILLED_ERROR_MESSAGE } from '~/apollo/errors/RequestRepeatNotFullfilled'

export enum ProductType {
  Lottery = 'Lottery',
  LotterySubscription = 'LotterySubscription',
  Scratchcard = 'Scratchcard',
}
const currentTopUpPayment = ref<Maybe<StoredPaymentInstrument>>(null)

const checkoutQuery = ref<ReturnType<typeof useCheckoutQuery>>()

export const useCheckout = (productName?: string) => {
  const paymentId = useState('paymentId', (): Maybe<string> => null)
  const canUseBalance = useState('balance', (): boolean => false)

  const { deleteProductId, product, productId } = useCachedProduct(productName)

  if (!product)
    throw new Error(
      'useCheckout composable can only be used in valid lottery/scratchcard routes or with a valid product name as argument.',
    )

  const isAddFundsEnabled = useIsFeatureFlagEnabled(FEATURE_FLAGS.addFunds)

  const hasProduct = computed(() => !!productId.value)
  const pending = ref(false)

  if (!checkoutQuery.value) {
    checkoutQuery.value = useCheckoutQuery(
      {
        licensedTerritory: useLicensedTerritory({ raw: true }),
        productId: productId.value,
      },
      {
        context: {
          ...setRepeatOptions<CheckoutQuery>({
            delay: 1_000,
            errorOnNotFulfilled: true,
            fixedDelay: true,
            repeatConditions: (response) =>
              response.data?.checkout.state === CheckoutState.Pending,
          }),
          ...{
            timeout: 20_000,
          },
        },
        enabled: hasProduct,
        fetchPolicy: 'network-only',
      },
    )
  }

  checkoutQuery.value.onError((error) => {
    switch (error.message) {
      case REPEAT_NOT_FULFILLED_ERROR_MESSAGE:
        pending.value = true
        break
      case 'validation.product.expired':
        deleteProductId()
        navigateTo(`/scratchcard/${product}`)
        break
      default:
        navigateTo('/play-now')
    }
  })

  checkoutQuery.value.onResult(({ data, loading }) => {
    if (loading || !data) return
    canUseBalance.value = !!data.checkout.payment?.payWithBalance

    if (data.checkout.payment?.__typename === 'PayWithTopUp') {
      paymentId.value = data.checkout.payment.paymentId
    }
  })

  const checkoutResult = computed(() => toValue(checkoutQuery.value?.result))

  const checkoutLoading = computed(
    () => toValue(checkoutQuery.value?.loading) ?? true,
  )

  const isPending = computed(() => pending.value)

  const isFundingNeeded = computed(
    () =>
      isAddFundsEnabled &&
      (checkoutResult.value?.checkout.payment?.topUpAmount?.value || 0) > 0,
  )

  const isSubscription = computed(() => {
    if (!checkoutResult.value?.checkout.product) return false
    if (checkoutResult.value.checkout.product.__typename !== 'LotteryProduct')
      return false
    return checkoutResult.value.checkout.product.subscription
  })

  const priceBreakdown = computed(
    () => checkoutResult.value?.checkout.product?.priceBreakdown ?? null,
  )

  const productType = computed(() => {
    if (!checkoutResult.value?.checkout.product) return null
    return checkoutResult.value.checkout.product.__typename ===
      'ScratchcardProduct'
      ? ProductType.Scratchcard
      : ProductType.Lottery
  })

  const scratchcardExpired = computed(() => {
    if (productType.value !== ProductType.Scratchcard) return false
    return new Date() > new Date(scratchcardExpiresAt.value)
  })

  const scratchcardExpiresAt = computed(
    () =>
      (checkoutResult.value?.checkout?.product as ScratchcardProduct)
        ?.expiresAt,
  )

  const setTopUpPayment = (_topUpPayment: StoredPaymentInstrument) => {
    currentTopUpPayment.value = _topUpPayment
  }

  const storedPayments = computed(
    () => (checkoutResult.value?.checkout.payment as PayWithTopUp)?.stored,
  )

  const initialPayments = computed(
    () => (checkoutResult.value?.checkout.payment as PayWithTopUp)?.initial,
  )

  const topUpPayment = computed(() => currentTopUpPayment.value)

  return {
    canUseBalance,
    checkoutLoading,
    checkoutRefetch: checkoutQuery.value.refetch,
    checkoutResult,
    initialPayments,
    isFundingNeeded,
    isPending,
    isSubscription,
    paymentId,
    priceBreakdown,
    productType,
    reset: () => (checkoutQuery.value = undefined),
    scratchcardExpired,
    scratchcardExpiresAt,
    setTopUpPayment,
    storedPayments,
    topUpPayment,
  }
}
