import {
  ApolloLink,
  type FetchResult,
  Observable,
  type Operation,
} from '@apollo/client/core'

import RequestTimeoutError from '~/apollo/errors/RequestTimeoutError'

const DEFAULT_TIMEOUT_IN_MS = 10_000

const isSubscription = (operation: Operation) =>
  operation.query.definitions.some(
    (definition) =>
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription',
  )

/**
 * You can use this link to timeout a request after a specified number of milliseconds.
 * Overwrite the default timeout by passing a context.timeout in milliseconds
 *
 * @example
 * useQuery(
 *  { foo: 'bar' },
 *  { context: { timeout: 5000 },
 * )
 */
export const timeoutLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext()
  const timeout = context.timeout || DEFAULT_TIMEOUT_IN_MS

  if (timeout <= 0 || isSubscription(operation)) return forward(operation)

  const fetchOptions = context.fetchOptions || {}
  const controller = fetchOptions.controller ?? new AbortController()

  operation.setContext({
    fetchOptions: {
      ...fetchOptions,
      controller,
      signal: controller.signal,
    },
  })

  const chainObservable = forward(operation)

  const timeoutObservable = new Observable<FetchResult>((subscriber) => {
    // eslint-disable-next-line prefer-const
    let timer: NodeJS.Timeout

    const subscription = chainObservable.subscribe(
      (result) => {
        clearTimeout(timer)
        subscriber.next(result)
        subscriber.complete()
      },
      (error) => {
        clearTimeout(timer)
        subscriber.error(error)
        subscriber.complete()
      },
    )

    timer = setTimeout(() => {
      controller.abort()

      const currentContext = operation.getContext()
      const currentFetchOptions = currentContext.fetchOptions || {}

      if (
        currentFetchOptions.controller === controller &&
        currentFetchOptions.signal === controller.signal
      ) {
        operation.setContext({
          fetchOptions: {
            ...currentFetchOptions,
            controller: null,
            signal: null,
          },
        })
      }

      subscriber.error(new RequestTimeoutError(timeout))
      subscription.unsubscribe()
    }, timeout)

    return () => {
      clearTimeout(timer)
      subscription.unsubscribe()
    }
  })

  return timeoutObservable
})
