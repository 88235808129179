import type { TypePolicies } from '@apollo/client/cache'
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common'

import {
  type PlayerPayload,
  Status as UserStatus,
  type VerificationPayload,
  VerificationPayloadName,
  VerificationPayloadStatus,
} from '~/@types/generated/backend/graphql-schema-types'
import { cache } from '~/apollo/local/cache'

type Verifications = PlayerPayload['verifications']
type IdologyVerification = PlayerPayload['idologyVerification']
type Status = PlayerPayload['status']

export const PersistedStateVerificationData = {
  VerifcationHasSendSafelyUpload: 'verifcation_has_send_safely_upload',
} as const

export type VerificationDataLocalState = {
  automaticAgeVerification: PlayerPayload['automaticAgeVerification']
  didAutomaticAgeVerificationFail: PlayerPayload['didAutomaticAgeVerificationFail']
  didAutomaticAgeVerificationSucceed: PlayerPayload['didAutomaticAgeVerificationSucceed']
  hasAutomaticAgeVerificationStarted: PlayerPayload['hasAutomaticAgeVerificationStarted']
  hasManualAgeVerification: PlayerPayload['hasManualAgeVerification']
  hasSendSafelyUpload: PlayerPayload['hasSendSafelyUpload']
  idologyUrl: PlayerPayload['idologyUrl']
  idologyVerification: PlayerPayload['idologyVerification']
  isAutomaticAgeVerificationPending: PlayerPayload['isAutomaticAgeVerificationPending']
  isIdologyPending: PlayerPayload['isIdologyPending']
  isRegistered: PlayerPayload['isRegistered']
  isVerified: PlayerPayload['isVerified']
  needsManualVerification: PlayerPayload['needsManualVerification']
  shouldVerifyPhone: PlayerPayload['shouldVerifyPhone']
}

const byManualUrl = (verification: Verifications[0]) =>
  verification.manualVerificationUrl

const byName =
  (searchedName: VerificationPayloadName) =>
  ({ name }: VerificationPayload) =>
    searchedName === name

const hasStatus = (readField: ReadFieldFunction, status: UserStatus) =>
  readField<Status>('status') === status

const hasVerificationStatus = <
  K extends keyof PlayerPayload,
  T extends PlayerPayload[K],
>(
  readField: ReadFieldFunction,
  field: K,
  status: VerificationPayloadStatus,
) => readField<T>(field)?.status === status

const getVerification = (
  readField: ReadFieldFunction,
  name: VerificationPayloadName,
) => readField<Verifications>('verifications')?.find(byName(name))

export const verificationTypePolicies: TypePolicies = {
  PlayerPayload: {
    fields: {
      automaticAgeVerification: {
        read: (_, { readField }) =>
          getVerification(
            readField,
            VerificationPayloadName.AutomaticAgeVerification,
          ),
      },
      didAutomaticAgeVerificationFail: {
        read: (_, { readField }) =>
          hasVerificationStatus(
            readField,
            'automaticAgeVerification',
            VerificationPayloadStatus.Failure,
          ),
      },
      didAutomaticAgeVerificationSucceed: {
        read: (_, { readField }) =>
          hasVerificationStatus(
            readField,
            'automaticAgeVerification',
            VerificationPayloadStatus.Success,
          ),
      },
      formattedPhoneNumber: {
        read: (_, { readField }) => {
          const phone = readField<PlayerPayload['phone']>('phone')

          if (!phone) return '-'

          return formatPhoneNumber(phone)
        },
      },
      hasAutomaticAgeVerificationStarted: {
        read: (_, { readField }) =>
          !hasVerificationStatus(
            readField,
            'automaticAgeVerification',
            VerificationPayloadStatus.NotStarted,
          ),
      },
      hasManualAgeVerification: {
        read: (_, { readField }) =>
          readField<PlayerPayload['hasSendSafelyUpload']>(
            'hasSendSafelyUpload',
          ) ||
          readField<PlayerPayload['needsManualVerification']>(
            'needsManualVerification',
          ) ||
          readField<PlayerPayload['isIdologyPending']>('isIdologyPending'),
      },
      hasSendSafelyUpload: {
        read: (_, { readField }) => {
          const customerId =
            readField<PlayerPayload['customerId']>('customerId')

          return cache.readCache<boolean>(
            PersistedStateVerificationData.VerifcationHasSendSafelyUpload,
            false,
            { customerId },
          )
        },
      },
      idologyUrl: {
        read: (_, { readField }) => {
          const verifications = readField<Verifications>('verifications')

          const url = verifications?.find(byManualUrl)?.manualVerificationUrl
          return url ?? null
        },
      },
      idologyVerification: {
        read: (_, { readField }) =>
          getVerification(
            readField,
            VerificationPayloadName.IdUploadAgeVerification,
          ),
      },
      isAutomaticAgeVerificationPending: {
        read: (_, { readField }) =>
          hasVerificationStatus(
            readField,
            'automaticAgeVerification',
            VerificationPayloadStatus.Pending,
          ),
      },
      isIdologyPending: {
        read: (_, { readField }) => {
          const status = readField<IdologyVerification>(
            'idologyVerification',
          )?.status

          if (!status) return false
          return [
            VerificationPayloadStatus.Failure,
            VerificationPayloadStatus.Pending,
          ].includes(status)
        },
      },
      isRegistered: {
        read: (_, { readField }) => hasStatus(readField, UserStatus.Registered),
      },
      isVerified: {
        read: (_, { readField }) => hasStatus(readField, UserStatus.Verified),
      },
      needsManualVerification: {
        read: (_, { readField }) =>
          !!getVerification(
            readField,
            VerificationPayloadName.ManualAgeVerification,
          ),
      },
      shouldVerifyPhone: {
        read: (_, { readField }) => {
          const phone = readField<PlayerPayload['phone']>('phone')

          return (
            phone?.status !== VerificationPayloadStatus.Success &&
            phone?.status !== VerificationPayloadStatus.NotSupported
          )
        },
      },
    },
  },
}
