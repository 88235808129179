<template>
  <LcModal v-bind="$props" @close="emit('cancel')">
    <div class="flex flex-col gap-md w-full">
      <h2 v-if="$slots.headline" class="text-h4">
        <slot name="headline" />
      </h2>

      <div class="flex flex-col items-center">
        <slot />
      </div>
      <footer v-if="!noFooter" class="flex flex-col gap-md w-full">
        <LcButton
          v-if="!noConfirm"
          class="block"
          data-testid="modal-confirm-button"
          :ghost="confirmIsGhost"
          :loading="isConfirmLoading"
          @click="emit('confirm')"
        >
          <slot name="confirm">
            {{ $t('modal.button.confirm') }}
          </slot>
        </LcButton>

        <LcButton
          v-if="!noCancel"
          data-testid="modal-cancel-button"
          :ghost="cancelIsGhost"
          :variant="cancelVariant"
          @click="emit('cancel')"
        >
          <slot name="cancel">
            {{ $t('modal.button.cancel') }}
          </slot>
        </LcButton>
      </footer>
    </div>
  </LcModal>
</template>

<script lang="ts" setup>
import {
  LcButton,
  type LcButtonVariant,
  LcModal,
  type LcModalProps,
} from '@lottocom/frontend-components'

type ModalEmit = {
  (e: 'cancel'): void
  (e: 'confirm'): void
}

type ModalProps = {
  cancelIsGhost?: boolean
  cancelVariant?: LcButtonVariant
  confirmIsGhost?: boolean
  isConfirmLoading?: boolean
  noCancel?: boolean
  noConfirm?: boolean
  noFooter?: boolean
}

const emit = defineEmits<ModalEmit>()

withDefaults(defineProps<LcModalProps & ModalProps>(), {
  cancelIsGhost: true,
})
</script>
