<template>
  <!-- Parent div is needed to prevent layout shifting -->
  <div class="info-button flex" :class="variant">
    <LcButton
      v-bind="{ ariaLabel, ...$attrs }"
      icon="info"
      size="small"
      variant="tertiary"
      @click="openModal"
    />

    <ClientOnly>
      <LcModal
        v-bind="{ isVisible }"
        :close-triggers="['escape', 'click-outside']"
        spacing="medium"
        @close="closeModal"
      >
        <div class="flex flex-col gap-md w-full text-left">
          <header class="flex justify-between">
            <span class="text-h4">
              <slot name="headline" />
            </span>

            <LcButton
              class="-mt-xs -mr-xs"
              icon="close"
              size="small"
              variant="tertiary"
              @click="closeModal"
            />
          </header>

          <section class="text-left">
            <slot />
          </section>
        </div>
      </LcModal>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { LcButton, LcModal } from '@lottocom/frontend-components'

type InfoButtonVariant = 'discreet' | 'prominent'

type InfoButtonProps = {
  ariaLabel: string
  variant?: InfoButtonVariant
}

withDefaults(defineProps<InfoButtonProps>(), {
  variant: 'discreet',
})

const isVisible = ref(false)

const closeModal = () => (isVisible.value = false)
const openModal = () => (isVisible.value = true)
</script>

<style lang="scss" scoped>
.info-button {
  &.discreet {
    :deep(.lc-icon) {
      color: color('secondary700');
      font-size: $font-size-h6;
    }
  }
}
</style>
