<template>
  <LcImage v-if="imageProps" v-bind="{ alt, densities, ...imageProps }" />
</template>

<script lang="ts" setup>
import { LcImage } from '@lottocom/frontend-components'

export type IllustrationProps = {
  alt?: string
  densities?: string
  name: string
}

const props = withDefaults(defineProps<IllustrationProps>(), {
  alt: '',
  densities: '1x',
})

const { getIllustration } = useAsset()

const imageProps = computed(() => getIllustration(props.name))
</script>
