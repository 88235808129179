import validate from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setup_45global from "/builds/lottocom/shop-frontend/frontend/middleware/01.setup.global.ts";
import seo_45global from "/builds/lottocom/shop-frontend/frontend/middleware/seo.global.ts";
import tracking_45global from "/builds/lottocom/shop-frontend/frontend/middleware/tracking.global.ts";
import manifest_45route_45rule from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setup_45global,
  seo_45global,
  tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "age-verification": () => import("/builds/lottocom/shop-frontend/frontend/middleware/age-verification.ts"),
  auth: () => import("/builds/lottocom/shop-frontend/frontend/middleware/auth.ts"),
  "logged-out": () => import("/builds/lottocom/shop-frontend/frontend/middleware/logged-out.ts"),
  "only-excluded-users": () => import("/builds/lottocom/shop-frontend/frontend/middleware/only-excluded-users.ts"),
  redirects: () => import("/builds/lottocom/shop-frontend/frontend/middleware/redirects.ts"),
  register: () => import("/builds/lottocom/shop-frontend/frontend/middleware/register.ts"),
  "self-excluded": () => import("/builds/lottocom/shop-frontend/frontend/middleware/self-excluded.ts")
}