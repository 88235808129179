export const getRandomNumber = (min: number, max: number) => {
  if (!window || !window.crypto) throw new Error('Missing Crypto API')

  const randomBuffer = new Uint32Array(1)
  window.crypto.getRandomValues(randomBuffer)

  const randomNumber = randomBuffer[0] / (0xffffffff + 1)
  min = Math.ceil(min)
  max = Math.floor(max)

  return Math.floor(randomNumber * (max - min + 1)) + min
}

export const formatPercentage = (value: number) =>
  Number(value / 100).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    style: 'percent',
  })
