import {
  type AddOn,
  LotteryType,
  type Stake,
} from '~/@types/generated/backend/graphql-schema-types'
import type { LotteryConfig } from '~/features/Lottery/apollo/cache'

const getNumberOfPlays = (config?: LotteryConfig) =>
  config?.allowedPlays.at(-1) ?? 1

export const isPerPlay = (addOn: AddOn) => addOn.perPlay

export const isNotPerPlay = (addOn: AddOn) => !addOn.perPlay

export const getInitialConfirmedPlays = (config?: LotteryConfig) =>
  Array.from(
    { length: getNumberOfPlays(config) },
    () => config?.type === LotteryType.NumberLottery,
  )

export const getInitialSelectedAddOns = (config?: LotteryConfig) =>
  config?.addOns.filter(isNotPerPlay).map((addOn) => ({
    name: addOn.name,
    value: addOn.mandatory ? addOn.options[0] : '',
  })) ?? []

export const getInitialPerPlayAddOns = (config: LotteryConfig) => {
  const perPlayAddOn = config.addOns.find(isPerPlay)
  if (!perPlayAddOn) return []

  return Array.from({ length: getNumberOfPlays(config) }, () => ({
    name: perPlayAddOn.name,
    value: perPlayAddOn.options.at(0)!,
  }))
}

export const getInitialSelectedNumbers = (config?: LotteryConfig) =>
  Array.from(
    { length: getNumberOfPlays(config) },
    () => config?.numbers.map<number[]>(() => []) ?? [],
  )

export const getInitialStake = (config: LotteryConfig) => config.stakes[0].price

export const getInitialStakes = (config: LotteryConfig) => {
  const stake = getInitialStake(config)

  let initialStake: Stake['price'][] = [stake]
  if (config?.type === LotteryType.PickLottery) {
    initialStake = Array.from({ length: getNumberOfPlays(config) }, () => stake)
  }

  return initialStake
}
