export const useTranslations = () => {
  const { getTranslation } = useI18nUtils()

  const getPerPlayTranslation = (
    lotteryId?: string,
    perPlayName?: string,
    perPlayValue?: string,
  ) =>
    getTranslation({
      fallback: getTranslation({
        key: `lottery.${perPlayName}.${perPlayValue}`,
      }),
      key: `lottery.${lotteryId}.${perPlayName}.${perPlayValue}`,
    })!

  return { getPerPlayTranslation }
}
