<template>
  <NuxtLink
    v-bind="{ ...passableProps, ...$attrs }"
    @click.prevent="handleClick"
  >
    <slot />

    <HyperLinkModal
      v-if="showModal"
      @cancel="showModal = false"
      @confirm="handleConfirm"
    />
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { NuxtLinkProps } from '#app'

/**
 * Needs to be single root to support scoped styling
 * https://github.com/vuejs/core/issues/5446
 */

type HyperLinkProps = NuxtLinkProps & {
  title?: string
}

const props = defineProps<HyperLinkProps>()
const showModal = ref(false)

const isEmail = computed(() => {
  if (typeof target.value !== 'string') return false
  return /mailto:/.test(target.value)
})

const isExternal = computed(() => {
  if (typeof target.value !== 'string') return false
  return isLink.value || isEmail.value
})

const isLink = computed(() => {
  if (typeof target.value !== 'string') return false
  return /https?:\/\//.test(target.value)
})

const needsDisclaimer = computed(() => {
  if (!isLink.value) return false
  const { hostname } = new URL(String(target.value))
  return !hostname.includes('lotto.com')
})

const passableProps = computed(() => {
  const { href, noPrefetch, prefetch, to, ..._props } = props
  return {
    ..._props,
    ...(!!to && { to }),
    ...(!!href && !to && { href }),
    ...(prefetch && { prefetch }),
    ...(noPrefetch && !prefetch && { noPrefetch }),
  }
})

const target = computed(() => props.to || props.href)

const handleClick = () => {
  if (!target.value) return

  if (!isExternal.value) {
    navigateTo(target.value)
    return
  }

  if (!needsDisclaimer.value && !isEmail.value) {
    navigateToExternal()
    return
  }

  if (isEmail.value) {
    window.location.href = String(target.value)
    return
  }

  showModal.value = true
}

const handleConfirm = () => {
  showModal.value = false
  navigateToExternal()
}

const navigateToExternal = () => {
  navigateTo(target.value, { external: true, open: { target: '_blank' } })
}
</script>
