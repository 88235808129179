export const customIdleCallback = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  _options?: { timeout?: number },
) => {
  const options = _options ?? { timeout: 1000 }
  if (process.client && typeof window.requestIdleCallback === 'function') {
    return window.requestIdleCallback(() => callback(), {
      timeout: options.timeout,
    })
  } else {
    return setTimeout(() => callback(), options.timeout)
  }
}
