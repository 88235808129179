<template>
  <LcImage
    v-if="imageProps && variant === 'image'"
    v-bind="imageProps"
    :alt="$t(`lottery.${lotteryId}`)"
    :class="['lottery-logo', 'image', size]"
  />
  <span v-else :class="['lottery-logo', 'text', size]" :style="theme">
    <slot>
      {{ $t(`lottery.${lotteryId}`) }}
    </slot>
  </span>
</template>

<script lang="ts" setup>
import { LcImage } from '@lottocom/frontend-components'

import type { LotteryId } from '~/@types/generated/backend/graphql-schema-types'

type LotteryLogoProps = {
  licensedTerritory: string
  lotteryId: LotteryId
  size?: 'large' | 'medium' | 'small'
  variant?: 'image' | 'text'
}

const props = withDefaults(defineProps<LotteryLogoProps>(), {
  size: 'medium',
  variant: 'image',
})

const { licensedTerritory, lotteryId } = toRefs(props)
const { getLotteryLogo } = useAsset()

const imageProps = computed(() =>
  getLotteryLogo(licensedTerritory.value, lotteryId.value),
)
const theme = computed(() => {
  const { createCssVar } = getLotteryCssVarConstructor(
    props.licensedTerritory,
    props.lotteryId,
  )

  return {
    '--lottery-logo-color': createCssVar('color-primary'),
  }
})
</script>

<style lang="scss" scoped>
.lottery-logo {
  word-break: break-word;

  &.large {
    max-height: 3rem;
  }

  &.medium,
  &.small {
    max-height: 2.25rem;
  }

  &.image {
    width: auto;
    max-width: unset;
  }

  &.text {
    display: inline-flex;
    align-items: center;
    color: var(--lottery-logo-color);
    font-weight: $font-weight-medium;
    line-height: $line-height-sm;
    text-transform: uppercase;

    &.large {
      font-size: $font-size-h3;
    }

    &.medium {
      font-size: $font-size-h4;
    }

    &.small {
      font-size: $font-size-caption;
    }
  }
}
</style>
