/* eslint-disable camelcase */
import { scratchcard } from '@lottocom/price-calculation'
import type { RouteRecordName } from 'vue-router'

import type {
  Money,
  PriceBreakdown,
  Product,
  Status as PlayerStatus,
  VerificationPayload,
  VerificationPayloadStatus,
} from '~/@types/generated/backend/graphql-schema-types'
import { ProductType } from '~/composables/checkout'
import ScratchcardPriceBreakdown = scratchcard.ScratchcardPriceBreakdown

type CreateCheckoutParams = {
  step_name: 'cart' | 'checkout_confirmation'
} & (
  | { items: Item[]; type: ProductType.Lottery }
  | { items: ScratchcardItem[]; type: ProductType.Scratchcard }
)

type CreatePayInParams = {
  amount: Money
  isFirstPurchase: boolean
  payInId: string
}

type ItemProps = {
  id: string
  name: string
  priceBreakdown: Product['priceBreakdown']
}

type ScratchcardItemProps = Omit<ItemProps, 'id'> & {
  quantity: number
}

type LotteryItemProps = ItemProps & {
  date: string
  id: string
  picks: Play[]
  stake: number
}

type LotteryOrScratchcard = ProductType.Lottery | ProductType.Scratchcard

export type ProductBasedTrackingPurchaseEventParams =
  TrackingPurchaseEventParams & {
    items: LotteryItem[] | ScratchcardItem[]
  }

export enum TrackingEvent {
  AccountRegistration = 'account_registration',
  AccountStatusUpdate = 'account_status_update',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  CData = 'c_data',
  ContentView = 'content-view',
  CustomExperiment = 'custom_experiment_event',
  CustomExperimentInit = 'custom_experiment',
  GTMJs = 'gtm.js',
  Init = 'init',
  PayIn = 'pay_in',
  Purchase = 'purchase',
  ViewItem = 'view_item',
}

export enum CustomExperimentEvent {
  PersonalizedTeaser = 'personalizedTeaser',
}

type CustomExperimentInitParams = {
  exp_id: CustomExperimentEvent
  exp_variant: string
}

type CustomExperimentEventParams = CustomExperimentInitParams & {
  exp_goal: string
  exp_value?: number
}

export const useTracking = () => {
  const { gtmEnabled, shop, version } = useRuntimeConfig().public
  const isEnabled = gtmEnabled && import.meta.client
  if (isEnabled) window.dataLayer = window.dataLayer || []

  const createBaseEvent = async () => {
    let event: NonNullable<unknown> | TrackingEvent = {}
    try {
      const { result } = useUserDataQuery({ fetchPolicy: 'cache-only' })
      const { customerId, email } = result.value?.player || {}

      event = {
        app_name: `${shop}-${version}`,
        // eslint-disable-next-line security-node/detect-insecure-randomness
        app_random: Math.random().toString(36).substring(2, 10),
        app_timestamp: Date.now(),
        ...(customerId && { cstid: customerId }),
        ...(email && { emh: await sha256(email) }),
        native_app: useCustomContext().isNativeApp,
      }
    } catch (_) {}
    return event as TrackingBaseEvent
  }

  const createItem = ({
    id,
    name,
    priceBreakdown,
  }: ItemProps): Omit<Item, 'convenience_fee' | 'price' | 'quantity'> => ({
    currency: priceBreakdown.grossTotal.currency,
    item_id: id.toLowerCase(),
    item_name: name.toLowerCase(),
  })

  const createLotteryItem = ({
    date,
    picks,
    priceBreakdown,
    stake,
    ...rest
  }: LotteryItemProps): LotteryItem => ({
    ...createItem({
      priceBreakdown,
      ...rest,
    }),
    convenience_fee:
      (priceBreakdown as PriceBreakdown).feePerPlaySlip.value / 100,
    lottery_date: date,
    lottery_stake: stake,
    picks,
    price: picks.reduce((total, play) => total + play.pick_price, 0),
    quantity: 1,
  })

  const createScratchcardItem = ({
    priceBreakdown,
    quantity,
    ...rest
  }: ScratchcardItemProps): ScratchcardItem => ({
    ...createItem({
      id: 'scratchcard',
      priceBreakdown,
      ...rest,
    }),
    convenience_fee:
      (priceBreakdown as ScratchcardPriceBreakdown).feeTotal.value /
      quantity /
      100,
    price: priceBreakdown.grossTotal.value / quantity / 100,
    quantity,
  })

  // <editor-fold desc="UserRegistrationTrackingEvents">
  const createAccountRegistrationEvent = () =>
    pushTrackingEvent({ event: TrackingEvent.AccountRegistration })

  const createAccountStatusEvent = (
    playerStatus: PlayerStatus,
    verifications: VerificationPayload[],
    phoneStatus?: Maybe<VerificationPayloadStatus>,
  ) => {
    const formattedVerifications = verifications.map(
      (verification) => `${verification.name}:${verification.status}`,
    )

    if (phoneStatus)
      formattedVerifications.push(`PhoneVerification:${phoneStatus}`)
    return pushTrackingEvent({
      account_reg_status: `${playerStatus};${formattedVerifications.sort()}`,
      event: TrackingEvent.AccountStatusUpdate,
    })
  }

  // </editor-fold>

  // <editor-fold desc="PurchaseFlowTrackingEvents">

  const createViewItemEvent = (type: LotteryOrScratchcard) =>
    // TODO getPriceBreakdown()
    pushTrackingEvent({
      ecommerce: {
        currency: 'USD', // getFrom priceBreakDown
        items: type === ProductType.Lottery ? [] : [], // TODO createItem() / createScratchcardItem()
        value: 1, // getFrom priceBreakDown
      },
      event: TrackingEvent.ViewItem,
    })

  const createAddToCartEvent = (type: LotteryOrScratchcard) =>
    // TODO getPriceBreakdown()
    pushTrackingEvent({
      ecommerce: {
        currency: 'USD', // getFrom priceBreakDown
        items: type === ProductType.Lottery ? [] : [], // TODO createItem() / createScratchcardItem()
        value: 1, // getFrom priceBreakDown
      },
      event: TrackingEvent.AddToCart,
    })

  const createCheckoutEvent = (params: CreateCheckoutParams) => {
    // TODO getPriceBreakdown()
    const { items, step_name, type } = params
    return pushTrackingEvent({
      checkout: {
        step_name,
        ...(type === ProductType.Lottery && {
          step_index: step_name === 'cart' ? 1 : 20,
        }),
      },
      ecommerce: {
        currency: 'USD', // getFrom priceBreakDown
        items,
        value: 1, // getFrom priceBreakDown
      },
      event: TrackingEvent.BeginCheckout,
    })
  }

  const createPayInEvent = (params: CreatePayInParams) => {
    pushTrackingEvent({
      ecommerce: {
        currency: params.amount.currency,
        first_transaction: params.isFirstPurchase,
        pay_in_id: params.payInId,
        value: params.amount.value / 100,
      },
      event: TrackingEvent.PayIn,
    })
  }

  const createPurchaseEvent = ({
    coupon,
    currency,
    isFirstPurchase: first_transaction,
    items,
    orderId: transaction_id,
    subscription,
    tax,
    value,
  }: ProductBasedTrackingPurchaseEventParams) =>
    pushTrackingEvent({
      ecommerce: {
        coupon: coupon || '',
        currency,
        first_transaction,
        items,
        ...(isDefined(subscription) && { subscription }),
        tax: tax || 0,
        transaction_id,
        value,
      },
      event: TrackingEvent.Purchase,
    })

  const createEcommerceResetEvent = () =>
    pushTrackingEvent({
      ecommerce: null,
    })

  // </editor-fold>

  // <editor-fold desc="Init and generics">

  const createNonceEvent = () => {
    if (!isEnabled) return
    window.dataLayer = window.dataLayer || []
    customIdleCallback(() =>
      window.dataLayer.push({
        nonce: useSafeNonce(),
      }),
    )
  }

  const createGTMJsEvent = () =>
    pushTrackingEvent({
      'event': TrackingEvent.GTMJs,
      'gtm.start': Date.now(),
    })

  const createInitEvent = () => {
    const adjustAttributes = useFlutter()?.getAdjustAttributes()
    const adjustToken = useFlutter()?.getAdjustToken()
    const otherLevelsAttributes = useFlutter()?.getOtherLevelsAttributes()
    const {
      sentry: { environment: env },
    } = useRuntimeConfig().public

    return pushTrackingEvent({
      ...(adjustAttributes && { app_adjust_attributes: adjustAttributes }),
      ...(adjustToken && { app_adjust_token: adjustToken }),
      env,
      event: TrackingEvent.Init,
      ...(otherLevelsAttributes && {
        ol: otherLevelsAttributes,
      }),
      originalLocation: window.location.href,
    } as TrackingInitEvent)
  }

  // still needed if emh is in base event?
  const createEmailHashEvent = () =>
    pushTrackingEvent({
      event: TrackingEvent.CData,
    })

  const createContentViewEvent = (fullPath: string, name?: RouteRecordName) =>
    pushTrackingEvent({
      'content-name': fullPath,
      'content-view-name': name,
      'event': TrackingEvent.ContentView,
    })

  const createCustomExperimentEvent = (params: CustomExperimentEventParams) => {
    pushTrackingEvent({
      event: TrackingEvent.CustomExperiment,
      ...params,
    })
  }

  const createCustomExperimentInitEvent = (
    params: CustomExperimentInitParams,
  ) =>
    pushTrackingEvent({
      event: TrackingEvent.CustomExperimentInit,
      ...params,
    })

  // </editor-fold>

  const pushTrackingEvent = (
    event:
      | TrackingAccountRegistrationEvent
      | TrackingAccountStatusUpdateEvent
      | TrackingAddToCartEvent
      | TrackingBeginCheckoutEvent
      | TrackingCheckoutPurchaseEvent
      | TrackingContentViewEvent
      | TrackingCustomExperimentEvent
      | TrackingCustomExperimentInitEvent
      | TrackingEcommerceResetEvent
      | TrackingEmailHashEvent
      | TrackingGtmEvent
      | TrackingInitEvent
      | TrackingNonceEvent
      | TrackingPayInEvent
      | TrackingViewItemEvent,
  ) => {
    if (!isEnabled) return
    window.dataLayer = window.dataLayer || []
    customIdleCallback(async () => {
      const base = await createBaseEvent()
      const _event = {
        ...base,
        ...event,
      }
      window.dataLayer.push(_event)
    })
  }

  return {
    createAccountRegistrationEvent,
    createAccountStatusEvent,
    createAddToCartEvent,
    createBaseEvent,
    createCheckoutEvent,
    createContentViewEvent,
    createCustomExperimentEvent,
    createCustomExperimentInitEvent,
    createEcommerceResetEvent,
    createEmailHashEvent,
    createGTMJsEvent,
    createInitEvent,
    createLotteryItem,
    createNonceEvent,
    createPayInEvent,
    createPurchaseEvent,
    createScratchcardItem,
    createViewItemEvent,
    isEnabled,
    pushTrackingEvent,
  }
}
/* eslint-enable camelcase */
