import { Mask, type MaskType } from 'maska'

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const mask = (mask: MaskType, value?: string) =>
  mask && value ? new Mask({ mask }).masked(value) : ''

export const normalizeKey = (key: string) =>
  key.toLowerCase().replace(/[^0-9a-z.]/gi, '')

export const pad = (value?: number) => {
  if (typeof value !== 'number') return ''
  if (value <= 0) return '00'
  if (value < 10) return `0${value}`
  return `${value}`
}

export const splitCamelCase = (string: string) =>
  string
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

export const toPascalCase = (string: string) =>
  string
    .toLowerCase()
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/\s+(.)(\w*)/g, (_, l1, l2) => `${l1.toUpperCase()}${l2}`)
    .replace(/\w/, (s) => s.toUpperCase())
