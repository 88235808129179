import type { ApolloError, NetworkError } from '@apollo/client/errors'

import type {
  CheckBonusCodeForPlaySlipQuery,
  Money,
} from '~/@types/generated/backend/graphql-schema-types'

export enum BonusCodeCondition {
  CustomerList = 'validation.bonusconditions.customerstatus.list',
  CustomerNew = 'validation.bonusconditions.customerstatus.new',
  CustomerReturning = 'validation.bonusconditions.customerstatus.returning',
  LoginRequired = 'validation.bonusconditions.loginrequired',
  Lotteries = 'validation.bonusconditions.lotteries',
  MaxPlays = 'validation.bonusconditions.maxplays',
  MinPlays = 'validation.bonusconditions.minplays',
  MinTotalCharge = 'validation.bonusconditions.mintotalcharge',
}

type BonusCodeCoupon = {
  fulfillable: boolean
  fulfilled: boolean
  info: {
    code: string
    description: string
    title: string
    value: {
      amount: number
    }
  }
  minTotalCharge?: Maybe<Money>
  productId?: string
  unfulfilledConditions: string[]
}

enum ToastType {
  GraphqlError = 'GraphqlError',
  NetworkError = 'NetworkError',
  NewFulfillable = 'NewFulfillable',
  NewFulfilled = 'NewFulfilled',
  NewUnfulfilled = 'NewUnfulfilled',
  SystemRemoveCoupon = 'SystemRemoveCoupon',
  UserRemoveCoupon = 'UserRemoveCoupon',
}

type ToastPayload = {
  [ToastType.GraphqlError]: string
  [ToastType.NetworkError]: NetworkError
  [ToastType.NewFulfillable]: NonNullable<BonusCodeCoupon>
  [ToastType.NewFulfilled]: NonNullable<BonusCodeCoupon>
  [ToastType.NewUnfulfilled]: NonNullable<BonusCodeCoupon>
  [ToastType.SystemRemoveCoupon]: NonNullable<BonusCodeCoupon>
  [ToastType.UserRemoveCoupon]: NonNullable<BonusCodeCoupon>
}

type ToastMessageMap<T extends ToastType> = {
  [K in T]: (payload: ToastPayload[K]) => ToastOptions
}

type ValidationResult =
  CheckBonusCodeForPlaySlipQuery['checkBonusCodeForPlaySlip']

export const BONUS_CODE_TOAST_ID = 'use-bonus-code'
export const BONUS_CODE_TOAST_ID_REMOVED_BY_SYSTEM =
  'use-bonus-code-removed-by-system'

const coupon = ref<Maybe<BonusCodeCoupon>>(null)
const isLoading = ref(false)

export const useBonusCode = () => {
  const { n, t } = useI18n()
  const { params, query } = useRoute()
  const toaster = useToaster()

  const product = params.lotteryId || params.slug || query.p
  const productId = product ? String(product) : null

  if (!productId)
    throw new Error(
      'useBonusCode composable can only be used in valid lottery or scratchcard routes',
    )

  let playSlip: Maybe<ReturnType<typeof usePlaySlip>> = null
  let scratchcardGame: Maybe<ReturnType<typeof useScratchcardGame>> = null

  try {
    playSlip = usePlaySlip()
  } catch {
    scratchcardGame = useScratchcardGame()
  }

  const toastMessages: ToastMessageMap<ToastType> = {
    [ToastType.GraphqlError]: (message) => ({
      autoClose: false,
      headline: t('bonuscodeinput.toast.error.generic.headline'),
      message: t(`bonuscodeinput.toast.error.${message}`),
      type: 'danger',
    }),
    [ToastType.NetworkError]: (error) => ({
      autoClose: false,
      message: error?.message ?? '',
      type: 'danger',
    }),
    [ToastType.NewFulfillable]: (coupon) => ({
      autoClose: false,
      headline: t('bonuscodeinput.toast.apply.unfulfilled.headline'),
      message: [
        coupon.info.title,
        coupon.unfulfilledConditions.map((condition) =>
          toTranslation(coupon, condition as BonusCodeCondition),
        )[0],
      ],
      type: 'warning',
    }),
    [ToastType.NewFulfilled]: (coupon) => ({
      headline: t('bonuscodeinput.toast.apply.fulfilled.headline'),
      message: [
        coupon.info.title,
        t('bonuscodeinput.toast.apply.fulfilled.text'),
      ],
      type: 'info',
    }),
    [ToastType.NewUnfulfilled]: (coupon) => ({
      autoClose: false,
      headline: t('bonuscodeinput.toast.error.generic.headline'),
      message: [
        t(
          'bonuscodeinput.toast.error.validation.bonusconditions.unfulfillable',
        ),
        coupon.unfulfilledConditions.map((condition) =>
          toTranslation(coupon, condition as BonusCodeCondition),
        )[0],
      ],
      type: 'danger',
    }),
    [ToastType.SystemRemoveCoupon]: (coupon) => ({
      autoClose: false,
      headline: t('bonuscodeinput.toast.remove.system.headline'),
      message: [
        coupon.info.title,
        t('bonuscodeinput.toast.remove.system.text', {
          code: coupon.info.code,
        }),
      ],
      type: 'warning',
    }),
    [ToastType.UserRemoveCoupon]: (coupon) => ({
      headline: t('bonuscodeinput.toast.remove.user.headline'),
      message: [
        coupon.info.title,
        t('bonuscodeinput.toast.remove.user.text', {
          code: coupon.info.code,
        }),
      ],
      type: 'warning',
    }),
  }

  const currentPrice = computed(
    () =>
      playSlip?.currentPrice?.value ??
      scratchcardGame?.currentPrice?.value ??
      null,
  )

  const addToast = async (options: ToastOptions, id = BONUS_CODE_TOAST_ID) => {
    toaster.removeToast(id)
    await waitMs(150)

    toaster.addToast(options, id)
  }

  const applyBonusCode = async (bonusCode: string) => {
    if (isLoading.value) return

    const { productId: checkoutProductId } = useCachedProduct()
    if (!checkoutProductId.value) return

    try {
      isLoading.value = true

      const { mutate } = useApplyBonusCodeMutation()
      const result = await mutate({
        bonusCode,
        productId: checkoutProductId.value,
      })

      if (result?.data) {
        handleNewCoupon(result.data.applyBonusCode)
      }
    } catch (error) {
      handleError(error as ApolloError)
    } finally {
      isLoading.value = false
    }
  }

  const checkBonusCodeForPlaySlip = async (bonusCode: string) => {
    if (!currentPrice.value || isLoading.value || !playSlip) return
    const { licensedTerritory, lotteryId } = playSlip

    try {
      isLoading.value = true

      const payload = await useCheckBonusCodeForPlaySlipLazyQuery(
        {
          input: {
            bonusCode,
            licensedTerritory,
            lotteryId,
            priceBreakdown: currentPrice.value,
          },
        },
        {
          fetchPolicy: 'network-only',
        },
      ).load()

      if (payload) {
        handleNewCoupon(payload.checkBonusCodeForPlaySlip)
      }
    } catch (error) {
      handleError(error as ApolloError)
    } finally {
      isLoading.value = false
    }
  }

  const getBonusCodeInfo = async (bonusCode: string) => {
    if (isLoading.value) return

    try {
      isLoading.value = true

      const payload = await useBonusCodeInfoLazyQuery(
        {
          bonusCode,
        },
        {
          fetchPolicy: 'network-only',
        },
      ).load()

      if (payload) {
        setCoupon({
          fulfillable: true,
          fulfilled: true,
          info: payload.bonusCodeInfo,
          productId,
          unfulfilledConditions: [],
        })
      }
    } catch (error) {
      handleError(error as ApolloError)
    } finally {
      isLoading.value = false
    }
  }

  const handleError = (error: ApolloError | Error): void => {
    if ('graphQLErrors' in error && !!error.graphQLErrors.length) {
      const [{ message }] = error.graphQLErrors
      addToast(toastMessages[ToastType.GraphqlError](message))
    }

    if ('networkError' in error && error.networkError) {
      addToast(toastMessages[ToastType.NetworkError](error))
    }

    removeCouponBySystem()
  }

  const handleNewCoupon = (newCoupon: ValidationResult) => {
    removeAllToasts()

    const preparedCoupon: BonusCodeCoupon = {
      fulfillable: newCoupon.fulfillable,
      fulfilled: newCoupon.fulfilled,
      info: newCoupon.info,
      minTotalCharge: newCoupon.minTotalCharge,
      unfulfilledConditions: newCoupon.unfulfilledConditions,
    }

    if (preparedCoupon.fulfillable) {
      setCoupon(preparedCoupon)

      if (preparedCoupon.fulfilled) {
        addToast(toastMessages[ToastType.NewFulfilled](preparedCoupon))
        return
      }

      addToast(toastMessages[ToastType.NewFulfillable](preparedCoupon))
      return
    }

    addToast(toastMessages[ToastType.NewUnfulfilled](preparedCoupon))
    removeCouponBySystem()
  }

  const removeAllToasts = () => {
    ;[BONUS_CODE_TOAST_ID, BONUS_CODE_TOAST_ID_REMOVED_BY_SYSTEM].forEach(
      toaster.removeToast,
    )
  }

  const removeCoupon = async () => {
    if (isLoading.value) return

    const { productId: checkoutProductId } = useCachedProduct()
    if (!coupon.value || !checkoutProductId.value) return

    try {
      isLoading.value = true

      const { mutate } = useRemoveBonusCodeMutation()
      await mutate({
        bonusCode: coupon.value.info.code,
        productId: checkoutProductId.value,
      })

      resetBonusCode()
    } catch (error) {
      handleError(error as ApolloError)
    } finally {
      isLoading.value = false
    }
  }

  const removeCouponBySystem = () => {
    if (!coupon.value) return

    addToast(
      toastMessages[ToastType.SystemRemoveCoupon](coupon.value),
      BONUS_CODE_TOAST_ID_REMOVED_BY_SYSTEM,
    )
    setCoupon(null)
  }

  const resetBonusCode = (showToast = true) => {
    if (!coupon.value) return

    if (showToast) {
      addToast(toastMessages[ToastType.UserRemoveCoupon](coupon.value))
    }

    setCoupon(null)
    removeAllToasts()
  }

  const setCoupon = (value: Maybe<BonusCodeCoupon>) => {
    coupon.value = value ? { ...value, productId } : null
  }

  const toTranslation = (
    coupon: NonNullable<BonusCodeCoupon>,
    condition: BonusCodeCondition,
  ) => {
    let amount: number | string | undefined

    switch (condition) {
      case BonusCodeCondition.MaxPlays:
      case BonusCodeCondition.MinPlays:
        amount = coupon.info.value.amount
        break
      case BonusCodeCondition.MinTotalCharge:
        if (!currentPrice.value) break

        amount = n(
          (coupon.minTotalCharge!.value -
            currentPrice.value.chargeTotal.value -
            currentPrice.value.feeTotal.value) /
            100,
          {
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
          },
        )
        break
    }

    return t(`bonuscodeinput.toast.${condition}`, { amount })
  }

  onBeforeMount(() => {
    if (coupon.value?.productId === productId) return
    setCoupon(null)
  })

  return {
    applyBonusCode,
    checkBonusCodeForPlaySlip,
    coupon,
    currentPrice,
    getBonusCodeInfo,
    isLoading,
    removeCoupon,
    resetBonusCode,
  }
}

export const useCoupon = () => ({
  coupon,
})
