export const useSupportMail = () => {
  const {
    public: {
      i18nConfig: { supportEmail },
    },
  } = useRuntimeConfig()

  const getMailTo = (subject?: string) => {
    const querySubject = subject ? `?subject=${subject}` : ''
    return `mailto:${supportEmail}${querySubject}`
  }

  const sendSupportMail = (subject: string) => {
    window.location.href = getMailTo(subject)
  }

  return {
    getMailTo,
    sendSupportMail,
    supportEmail,
  }
}
