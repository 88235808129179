export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server || !useRuntimeConfig().public.gtmEnabled!) return

  nuxtApp.hook('app:mounted', () => {
    const {
      createContentViewEvent,
      createGTMJsEvent,
      createInitEvent,
      createNonceEvent,
    } = useTracking()
    const { fullPath, name } = useRoute()

    createNonceEvent()
    createInitEvent()
    createGTMJsEvent()
    createContentViewEvent(fullPath, name ?? '')
  })

  nuxtApp.hook('app:suspense:resolve', () => {
    window.dataLayer = window.dataLayer || []

    const fileName = {
      production: 'sst.js',
      staging: 'gtm.js',
    }

    const urlParams = {
      production: {
        gtm_auth: '9mkOWT05bQCXmsXNEAw9cQ',
        gtm_preview: 'env-1',
      },
      staging: {
        gtm_auth: 'I_Ixab44OOYzBW4Gf-92GQ',
        gtm_preview: 'env-202',
      },
    }

    const isProd = useRuntimeConfig().public.sentryEnvironment === 'production'
    const file = isProd ? fileName.production : fileName.staging

    const url = new URL(`https://sst.lotto.com/${file}`)
    const gtmId = useRuntimeConfig().public.gtmId as string
    url.search = new URLSearchParams({
      id: gtmId,
      ...(isProd ? urlParams.production : urlParams.staging),
    }).toString()
    try {
      loadScript({ options: { async: true }, src: url.href })
    } catch (_) {}
  })
})
