<template>
  <article class="price-list">
    <slot v-if="$slots.default" />

    <LcSeparator class="my-lg" />

    <LcDescriptionList class="gap-none">
      <dt class="subtotal">
        {{ $t('pricelist.labels.subtotal') }}
      </dt>
      <dd class="subtotal text-right">{{ chargeTotal }}</dd>

      <template v-if="showConvenienceFee">
        <dt class="flex gap-xs items-center">
          {{ $t('pricelist.labels.conveniencefee') }}

          <InfoButton
            :aria-label="$t('infobutton.conveniencefee.label')"
            class="h-auto"
          >
            <template #headline>
              {{ $t('infobutton.conveniencefee.headline') }}
            </template>
            {{ $t('infobutton.conveniencefee.message') }}
          </InfoButton>
        </dt>
        <dd class="text-right">{{ convenienceFee }}</dd>
      </template>

      <template v-if="showSalesTax">
        <dt>{{ $t('pricelist.labels.salestax') }}</dt>
        <dd class="text-right">{{ salesTax }}</dd>
      </template>
    </LcDescriptionList>
  </article>
</template>

<script lang="ts" setup>
import { LcDescriptionList, LcSeparator } from '@lottocom/frontend-components'

import type Money from '~/models/Money'

type PriceListProps = {
  chargeTotal: Money
  convenienceFee: Money
  salesTax: Money
  showSalesTax?: boolean
}

const props = withDefaults(defineProps<PriceListProps>(), {
  showSalesTax: false,
})

const { convenienceFee } = toRefs(props)
const showConvenienceFee = computed(() => convenienceFee.value.value > 0)
</script>

<style lang="scss" scoped>
.price-list {
  padding-right: 1.625rem;

  .subtotal {
    font-weight: $font-weight-medium;
    font-size: $font-size-h5;
  }
}
</style>
