<template>
  <Modal
    v-bind="{ isVisible }"
    confirm-is-ghost
    no-cancel
    @confirm="isVisible = false"
  >
    <template #headline>{{ $t('stateswitch.modal.headline') }}</template>

    <i18n-t keypath="stateswitch.modal.text" scope="global" tag="p">
      <template #state>
        <strong>
          {{ $t(`licensedterritory.${licensedTerritory}`) }}
        </strong>
      </template>
    </i18n-t>

    <Illustration class="illustration mt-md" name="location-redirect" />

    <template #confirm>{{ $t('stateswitch.modal.confirm') }}</template>
  </Modal>
</template>

<script lang="ts" setup>
const { licensedTerritory } = useStateSwitch()

const isVisible = defineModel('isVisible', { default: false, type: Boolean })
</script>

<style lang="scss" scoped>
.illustration {
  width: 24rem;
}
</style>
