export enum RedirectType {
  Cloudflare = 'cloudflare',
  Cookie = 'cookie',
}

export type StateSwitchOption = {
  href: string
  id: string
  selected: boolean
  state: string
  title: string
}

export const stateSwitchQueryParam = 'ts'
export const stateSwitchRedirectResultQueryParam = 'r_result'
export const stateSwitchRedirectTypeQueryParam = 'r_type'
export const REDIRECT_COOKIE_KEY = 'wwwRedirect'
