import type { TypePolicies } from '@apollo/client/cache'
import priceCalculation from '@lottocom/price-calculation'

import type { ScratchcardGame } from '~/@types/generated/backend/graphql-schema-types'
import { cache } from '~/apollo/local/cache'

export const PersistedStateScratchcard = {
  ScratchcardSelectedQuantity: 'scratchcard_selected_quantity',
} as const

type Available = ScratchcardGame['available']
type PriceList = ScratchcardGame['priceList']
type SelectedQuantity = ScratchcardGame['selectedQuantity']

export type ScratchcardGameLocalState = {
  currentPrice: ScratchcardGame['currentPrice']
  maxQuantity: ScratchcardGame['maxQuantity']
  minQuantity: ScratchcardGame['minQuantity']
  selectedQuantity: ScratchcardGame['selectedQuantity']
}

export const MAX_SELECTED_QUANTITY = 10
export const MIN_SELECTED_QUANTITY = 1

export const scratchcardTypePolicies: TypePolicies = {
  ScratchcardGame: {
    fields: {
      currentPrice: {
        read: (_, { readField }) => {
          const priceList = readField<PriceList>('priceList')
          if (!priceList) return null

          const selectedQuantity =
            readField<SelectedQuantity>('selectedQuantity') ?? 0

          // eslint-disable-next-line import/no-named-as-default-member
          return priceCalculation.scratchcard.calculatePrices(
            selectedQuantity,
            { ...priceList },
          )
        },
      },
      maxQuantity: {
        read: (_, { readField }) => {
          const availableCards = readField<Available>('available') ?? 0
          return availableCards <= MAX_SELECTED_QUANTITY
            ? availableCards
            : MAX_SELECTED_QUANTITY
        },
      },
      minQuantity: {
        read: () => MIN_SELECTED_QUANTITY,
      },
      selectedQuantity: {
        read: (_, { variables }) =>
          cache.readCache(
            PersistedStateScratchcard.ScratchcardSelectedQuantity,
            1,
            variables,
          ),
      },
    },
    merge: true,
  },
}
