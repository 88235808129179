import { default as indexW152CPSNFzMeta } from "/builds/lottocom/shop-frontend/frontend/pages/[lotteryId]/index.vue?macro=true";
import { default as optionsAUikv0mn6iMeta } from "/builds/lottocom/shop-frontend/frontend/pages/[lotteryId]/options.vue?macro=true";
import { default as add_45funds1mBcp4ktZYMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/add-funds.vue?macro=true";
import { default as indexqfcO8VmbghMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/index.vue?macro=true";
import { default as payment_45methodsbEdgcmgvGOMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/payment-methods.vue?macro=true";
import { default as checkhW7enJ3U95Meta } from "/builds/lottocom/shop-frontend/frontend/pages/account/payout/check.vue?macro=true";
import { default as indexKiuPHdSAltMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/payout/index.vue?macro=true";
import { default as successqo8jPvDX3EMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/payout/success.vue?macro=true";
import { default as edit_45phone_45numberKD2BihgWubMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/personal-data/edit-phone-number.vue?macro=true";
import { default as indexa8oYgIi5zhMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/personal-data/index.vue?macro=true";
import { default as close_45accountxOSZS69NouMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/close-account.vue?macro=true";
import { default as indexEtHyl3fB5RMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/index.vue?macro=true";
import { default as _91slug_93liVMizG1PLMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/limits/[slug].vue?macro=true";
import { default as indexjbonipLJ7PMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/limits/index.vue?macro=true";
import { default as limitskTrXOa0KI2Meta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/limits.vue?macro=true";
import { default as marketing_45updatesdWrMJDAbxhMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/marketing-updates.vue?macro=true";
import { default as securityx2myZAvVZwMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/security.vue?macro=true";
import { default as self_45exclusion8iDwv42PXcMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/settings/self-exclusion.vue?macro=true";
import { default as transactionsgl6An0dVagMeta } from "/builds/lottocom/shop-frontend/frontend/pages/account/transactions.vue?macro=true";
import { default as age_45restrictionjQZS4XaLeeMeta } from "/builds/lottocom/shop-frontend/frontend/pages/age-restriction.vue?macro=true";
import { default as indexrSeufcIUcIMeta } from "/builds/lottocom/shop-frontend/frontend/pages/checkout/index.vue?macro=true";
import { default as successE5U4uqAhZJMeta } from "/builds/lottocom/shop-frontend/frontend/pages/checkout/success.vue?macro=true";
import { default as cool_45offsB7XYCUDwUMeta } from "/builds/lottocom/shop-frontend/frontend/pages/cool-off.vue?macro=true";
import { default as forgot_45passwordxv7MbVYM0HMeta } from "/builds/lottocom/shop-frontend/frontend/pages/forgot-password.vue?macro=true";
import { default as indexvesE8kLvyXMeta } from "/builds/lottocom/shop-frontend/frontend/pages/index.vue?macro=true";
import { default as logingqDn3zwJExMeta } from "/builds/lottocom/shop-frontend/frontend/pages/login.vue?macro=true";
import { default as _91id_93PzaNFDqEmKMeta } from "/builds/lottocom/shop-frontend/frontend/pages/my-orders/scratchcard/[id].vue?macro=true";
import { default as _91id_937ayJtuivG8Meta } from "/builds/lottocom/shop-frontend/frontend/pages/my-orders/single-order/[id].vue?macro=true";
import { default as _91id_93QkfPWcQNyNMeta } from "/builds/lottocom/shop-frontend/frontend/pages/my-orders/subscription/[id].vue?macro=true";
import { default as accountj4lVYi0sCNMeta } from "/builds/lottocom/shop-frontend/frontend/pages/register/account.vue?macro=true";
import { default as addressWgPTLcmNHJMeta } from "/builds/lottocom/shop-frontend/frontend/pages/register/address.vue?macro=true";
import { default as nameLLLhK5AEKyMeta } from "/builds/lottocom/shop-frontend/frontend/pages/register/name.vue?macro=true";
import { default as personaldatamXrM7bLJrHMeta } from "/builds/lottocom/shop-frontend/frontend/pages/register/personaldata.vue?macro=true";
import { default as registerDPNoXQdyhUMeta } from "/builds/lottocom/shop-frontend/frontend/pages/register.vue?macro=true";
import { default as reset_45passwordXFZBRnqu2vMeta } from "/builds/lottocom/shop-frontend/frontend/pages/reset-password.vue?macro=true";
import { default as _91slug_938QLBzi3ZBBMeta } from "/builds/lottocom/shop-frontend/frontend/pages/results/[slug].vue?macro=true";
import { default as indexgFSfJFuVahMeta } from "/builds/lottocom/shop-frontend/frontend/pages/results/index.vue?macro=true";
import { default as indexRxTODhWcrNMeta } from "/builds/lottocom/shop-frontend/frontend/pages/scratchcard/[slug]/index.vue?macro=true";
import { default as self_45excludedvckr0bjdkkMeta } from "/builds/lottocom/shop-frontend/frontend/pages/self-excluded.vue?macro=true";
import { default as indexOTLu82KfriMeta } from "/builds/lottocom/shop-frontend/frontend/pages/service/index.vue?macro=true";
import { default as healthcIjj4Ozv1qMeta } from "/builds/lottocom/shop-frontend/frontend/pages/status/health.vue?macro=true";
import { default as two_45factorj2zG7WX1A2Meta } from "/builds/lottocom/shop-frontend/frontend/pages/two-factor.vue?macro=true";
import { default as success6dTufMpCjcMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/avs/success.vue?macro=true";
import { default as errorZQLUbDphLzMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/error.vue?macro=true";
import { default as indexGTvwXW8Ta4Meta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/index.vue?macro=true";
import { default as pendingWCzShfTtREMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/pending.vue?macro=true";
import { default as processinguk5KYxyD29Meta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/processing.vue?macro=true";
import { default as successXwAoupOR23Meta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/success.vue?macro=true";
import { default as indexUAubKHIb7GMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/index.vue?macro=true";
import { default as index8biW3Hx9T6Meta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/phone-number/index.vue?macro=true";
import { default as success0rF6jISqoHMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/success.vue?macro=true";
import { default as indexFs33mSoG45Meta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/upload/index.vue?macro=true";
import { default as successTOXNnFloOkMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification/upload/success.vue?macro=true";
import { default as verificationP4gs7EAehaMeta } from "/builds/lottocom/shop-frontend/frontend/pages/verification.vue?macro=true";
export default [
  {
    name: "lotteryId",
    path: indexW152CPSNFzMeta?.path ?? "/:lotteryId()",
    meta: indexW152CPSNFzMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/[lotteryId]/index.vue").then(m => m.default || m)
  },
  {
    name: "lotteryId-options",
    path: optionsAUikv0mn6iMeta?.path ?? "/:lotteryId()/options",
    meta: optionsAUikv0mn6iMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/[lotteryId]/options.vue").then(m => m.default || m)
  },
  {
    name: "account-add-funds",
    path: "/account/add-funds",
    meta: add_45funds1mBcp4ktZYMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/add-funds.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexqfcO8VmbghMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-payment-methods",
    path: "/account/payment-methods",
    meta: payment_45methodsbEdgcmgvGOMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "account-payout-check",
    path: "/account/payout/check",
    meta: checkhW7enJ3U95Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/payout/check.vue").then(m => m.default || m)
  },
  {
    name: "account-payout",
    path: "/account/payout",
    meta: indexKiuPHdSAltMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/payout/index.vue").then(m => m.default || m)
  },
  {
    name: "account-payout-success",
    path: "/account/payout/success",
    meta: successqo8jPvDX3EMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/payout/success.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data-edit-phone-number",
    path: "/account/personal-data/edit-phone-number",
    meta: edit_45phone_45numberKD2BihgWubMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/personal-data/edit-phone-number.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-data",
    path: "/account/personal-data",
    meta: indexa8oYgIi5zhMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/personal-data/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-close-account",
    path: "/account/settings/close-account",
    meta: close_45accountxOSZS69NouMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/close-account.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: indexEtHyl3fB5RMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: limitskTrXOa0KI2Meta?.name,
    path: "/account/settings/limits",
    meta: limitskTrXOa0KI2Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/limits.vue").then(m => m.default || m),
    children: [
  {
    name: "account-settings-limits-slug",
    path: ":slug()",
    meta: _91slug_93liVMizG1PLMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/limits/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-settings-limits",
    path: "",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/limits/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-settings-marketing-updates",
    path: "/account/settings/marketing-updates",
    meta: marketing_45updatesdWrMJDAbxhMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/marketing-updates.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-security",
    path: "/account/settings/security",
    meta: securityx2myZAvVZwMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/security.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-self-exclusion",
    path: "/account/settings/self-exclusion",
    meta: self_45exclusion8iDwv42PXcMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/settings/self-exclusion.vue").then(m => m.default || m)
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsgl6An0dVagMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/account/transactions.vue").then(m => m.default || m)
  },
  {
    name: "age-restriction",
    path: "/age-restriction",
    meta: age_45restrictionjQZS4XaLeeMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/age-restriction.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexrSeufcIUcIMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    meta: successE5U4uqAhZJMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: "cool-off",
    path: "/cool-off",
    meta: cool_45offsB7XYCUDwUMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/cool-off.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordxv7MbVYM0HMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexvesE8kLvyXMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logingqDn3zwJExMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "my-orders-scratchcard-id",
    path: "/my-orders/scratchcard/:id()",
    meta: _91id_93PzaNFDqEmKMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/my-orders/scratchcard/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-orders-single-order-id",
    path: "/my-orders/single-order/:id()",
    meta: _91id_937ayJtuivG8Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/my-orders/single-order/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-orders-subscription-id",
    path: "/my-orders/subscription/:id()",
    meta: _91id_93QkfPWcQNyNMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/my-orders/subscription/[id].vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerDPNoXQdyhUMeta || {},
    redirect: "/register/name",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/register.vue").then(m => m.default || m),
    children: [
  {
    name: "register-account",
    path: "account",
    meta: accountj4lVYi0sCNMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/register/account.vue").then(m => m.default || m)
  },
  {
    name: "register-address",
    path: "address",
    meta: addressWgPTLcmNHJMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/register/address.vue").then(m => m.default || m)
  },
  {
    name: "register-name",
    path: "name",
    meta: nameLLLhK5AEKyMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/register/name.vue").then(m => m.default || m)
  },
  {
    name: "register-personaldata",
    path: "personaldata",
    meta: personaldatamXrM7bLJrHMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/register/personaldata.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordXFZBRnqu2vMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "results-slug",
    path: "/results/:slug()",
    meta: _91slug_938QLBzi3ZBBMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/results/[slug].vue").then(m => m.default || m)
  },
  {
    name: "results",
    path: "/results",
    meta: indexgFSfJFuVahMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/results/index.vue").then(m => m.default || m)
  },
  {
    name: "scratchcard-slug",
    path: "/scratchcard/:slug()",
    meta: indexRxTODhWcrNMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/scratchcard/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "self-excluded",
    path: "/self-excluded",
    meta: self_45excludedvckr0bjdkkMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/self-excluded.vue").then(m => m.default || m)
  },
  {
    name: "service",
    path: "/service",
    meta: indexOTLu82KfriMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: "status-health",
    path: "/status/health",
    meta: healthcIjj4Ozv1qMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/status/health.vue").then(m => m.default || m)
  },
  {
    name: "two-factor",
    path: "/two-factor",
    meta: two_45factorj2zG7WX1A2Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/two-factor.vue").then(m => m.default || m)
  },
  {
    name: verificationP4gs7EAehaMeta?.name,
    path: "/verification",
    meta: verificationP4gs7EAehaMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification.vue").then(m => m.default || m),
    children: [
  {
    name: "verification-avs-success",
    path: "avs/success",
    meta: success6dTufMpCjcMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/avs/success.vue").then(m => m.default || m)
  },
  {
    name: "verification-id-scan-error",
    path: "id-scan/error",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/error.vue").then(m => m.default || m)
  },
  {
    name: "verification-id-scan",
    path: "id-scan",
    meta: indexGTvwXW8Ta4Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/index.vue").then(m => m.default || m)
  },
  {
    name: "verification-id-scan-pending",
    path: "id-scan/pending",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/pending.vue").then(m => m.default || m)
  },
  {
    name: "verification-id-scan-processing",
    path: "id-scan/processing",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/processing.vue").then(m => m.default || m)
  },
  {
    name: "verification-id-scan-success",
    path: "id-scan/success",
    meta: successXwAoupOR23Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/id-scan/success.vue").then(m => m.default || m)
  },
  {
    name: "verification",
    path: "",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/index.vue").then(m => m.default || m)
  },
  {
    name: "verification-phone-number",
    path: "phone-number",
    meta: index8biW3Hx9T6Meta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/phone-number/index.vue").then(m => m.default || m)
  },
  {
    name: "verification-success",
    path: "success",
    meta: success0rF6jISqoHMeta || {},
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/success.vue").then(m => m.default || m)
  },
  {
    name: "verification-upload",
    path: "upload",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "verification-upload-success",
    path: "upload/success",
    component: () => import("/builds/lottocom/shop-frontend/frontend/pages/verification/upload/success.vue").then(m => m.default || m)
  }
]
  }
]