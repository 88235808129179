export type LicensedTerritory = ObjectValues<typeof LicensedTerritories>
export const LicensedTerritories = {
  US_AR: 'US-AR',
  US_AZ: 'US-AZ',
  US_CO: 'US-CO',
  US_FL: 'US-FL',
  US_MA: 'US-MA',
  US_MN: 'US-MN',
  US_NE: 'US-NE',
  US_NJ: 'US-NJ',
  US_NY: 'US-NY',
  US_OH: 'US-OH',
  US_OR: 'US-OR',
  US_TX: 'US-TX',
} as const
