import type { Phone } from '~/@types/generated/auth/graphql-schema-types'

export const formatPhoneNumber = (phone: Phone) => {
  const { countryCode, number } = phone

  return isUsNumber(countryCode)
    ? `${countryCode} ${mask('(###) ###-####', number)}`
    : `${countryCode} ${number}`
}

export const isUsNumber = (countryCode: string) =>
  countryCode === US_COUNTRY_CODE

export const US_COUNTRY_CODE = '+1'
