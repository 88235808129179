import { addDays } from 'date-fns'

export enum AdjustEvent {
  FIRST_TIME_DEPOSIT = 'FTD',
  REGISTRATION = 'REG',
}

export const useFlutter = () => {
  if (import.meta.server || !useCustomContext().isNativeApp) return
  const { callHandler: flutterCallHandler } = window.flutter_inappwebview

  const getAdjustAttributes = async (): Promise<Maybe<AdjustAttributes>> => {
    try {
      const attributesString = await window.flutter_inappwebview.callHandler(
        'getAdjustAttributes',
      )
      if (!attributesString) return null
      return JSON.parse(attributesString)
    } catch (_) {
      return null
    }
  }

  const getAdjustToken = async () => {
    try {
      return await window.flutter_inappwebview.callHandler(
        'getAdjustTrackerToken',
      )
    } catch (_) {
      return null
    }
  }

  const getOtherLevelsAttributes = async () => {
    try {
      return await window.flutter_inappwebview.callHandler(
        'getOtherlevelsDataObject',
      )
    } catch (_) {
      return null
    }
  }

  const getLocationSmartProfile = async (id: string) => {
    try {
      return await window.flutter_inappwebview.callHandler(
        'locationsmartProfile',
        id,
      )
    } catch (_) {
      return null
    }
  }

  const requestCameraPermissions = () => {
    window.flutter_inappwebview.callHandler('requestCameraPermissions')
  }

  const requestNotificationPermission = async (daysUntilNextPrompt = 30) => {
    const notificationsCookie = useCookie<{ promptedAt: Date }>(
      'notification_expires',
      {
        expires: addDays(new Date(), daysUntilNextPrompt),
      },
    )

    if (!notificationsCookie.value) {
      await window.flutter_inappwebview.callHandler(
        'requestNotificationPermissions',
      )
      notificationsCookie.value = { promptedAt: new Date() }
    }
  }

  const requestPushNotificationPermissionStatus = () =>
    window.flutter_inappwebview.callHandler(
      'getPushNotificationPermissionStatus',
    )

  const setOtherLevelsCustomerId = (customerId: string) => {
    try {
      window.flutter_inappwebview.callHandler('setCustomerId', customerId)
    } catch (_) {}
  }

  const showLocationPermissionDialog = async () => {
    try {
      await window.flutter_inappwebview.callHandler(
        'showLocationPermissionDialog',
      )
    } catch (_) {}
  }

  const showPushNotificationSettingsDialog = () =>
    window.flutter_inappwebview.callHandler(
      'showPushNotificationSettingsDialog',
    )

  const trackAdjustEvent = async (eventName: AdjustEvent) => {
    if (useRuntimeConfig().public.adjustEnabled !== 'true') return
    try {
      await window.flutter_inappwebview.callHandler(
        'trackAdjustEvent',
        eventName,
      )
    } catch (_) {}
  }

  return {
    flutterCallHandler,
    getAdjustAttributes,
    getAdjustToken,
    getLocationSmartProfile,
    getOtherLevelsAttributes,
    requestCameraPermissions,
    requestNotificationPermission,
    requestPushNotificationPermissionStatus,
    setOtherLevelsCustomerId,
    showLocationPermissionDialog,
    showPushNotificationSettingsDialog,
    trackAdjustEvent,
  }
}
