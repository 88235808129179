import type { Scope } from '@sentry/types'
import type { LogFn } from 'pino'

import type { NuxtApp } from '#app'
import { LottoLogLevel } from '~/logging/types'

type CustomLogFn = LogFn & {
  (msg: string): void
}

export const useLogging = () => {
  let app: NuxtApp

  try {
    app = useNuxtApp()
  } catch (error) {
    throw createError({
      statusMessage: `useLogging: ${
        (error as Error)?.message || 'useNuxtApp ist not available'
      }`,
    })
  }

  const instance = import.meta.server && app ? app.$logger : console

  const logSentry: LogSentry = (
    error,
    level,
    component,
    type,
    message,
    extra,
  ) => {
    const sentry = import.meta.server ? app?.$sentryNode : app?.$sentry
    if (!sentry) return

    sentry.withScope((scope: Scope) => {
      scope.setTags({
        component,
        type,
      })

      scope.setExtras({
        message,
        ...(extra && { extra }),
      })

      let eventId

      if (error && level === 'error' && error instanceof Error) {
        eventId = sentry.captureException(error)
      } else {
        eventId = sentry.captureMessage(message, level)
      }

      if (import.meta.dev)
        instance.debug({ component, eventId }, 'fired Sentry capture')
    })
  }

  const methods: { [key in LottoLogLevel]: CustomLogFn } = {
    [LottoLogLevel.Debug]: (...args: any) =>
      instance[LottoLogLevel.Debug](...(args as Parameters<LogFn>)),
    [LottoLogLevel.Error]: (...args: any) =>
      instance[LottoLogLevel.Error](...(args as Parameters<LogFn>)),
    [LottoLogLevel.Info]: (...args: any) =>
      instance[LottoLogLevel.Info](...(args as Parameters<LogFn>)),
    [LottoLogLevel.Warn]: (...args: any) =>
      instance[LottoLogLevel.Warn](...(args as Parameters<LogFn>)),
  }

  return {
    ...methods,
    log: logSentry,
  }
}
