import { MarketingChannel } from '~/@types/generated/backend/graphql-schema-types'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', async () => {
    if (!useCustomContext().isNativeApp || !useAuth().isLoggedIn.value) return

    const { log } = useLogging()
    const status = await useFlutter()!.requestPushNotificationPermissionStatus()

    try {
      await useSetMarketingChannelPreferencesMutation().mutate({
        input: {
          activated: status === 'granted',
          channel: MarketingChannel.PushNotification,
        },
      })
    } catch (error) {
      log(
        error,
        'error',
        'marketing.ts',
        'marketing.plugin',
        'error during set of marketing channel preferences',
      )
    }
  })
})
