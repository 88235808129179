<template>
  <LcHeader
    v-bind="{ leftButtonIcon, withFlyOut }"
    :fly-out-toggle-aria-label="$t('scratchcard.header.button.flyout')"
    fly-out-toggle-class="text-md"
    :left-button-aria-label="$t('scratchcard.header.button.goback')"
    left-button-icon="arrow-left"
    @click="handleClick"
  >
    <template #headline>
      <ScratchcardTitle v-bind="{ color, title }" />
    </template>

    <template #fly-out-toggle>
      {{ priceListProps.chargeTotal }}
    </template>

    <template #fly-out-content>
      <PriceList v-bind="priceListProps">
        <ScratchcardPriceBreakdownList />
      </PriceList>
    </template>
  </LcHeader>
</template>

<script lang="ts" setup>
import { LcHeader, type LcHeaderProps } from '@lottocom/frontend-components'

import Money from '~/models/Money'

type ScratchcardHeaderProps = Pick<
  LcHeaderProps,
  'leftButtonIcon' | 'withFlyOut'
> & {
  onClick?: () => void
}

const props = withDefaults(defineProps<ScratchcardHeaderProps>(), {
  leftButtonIcon: 'arrow-left',
})

const { navigateBack } = useRouterUtils()
const { currentPrice, name, visualParameters } = useScratchcardGame()

const color = computed(() => visualParameters.value?.titleColor)
const title = computed(() => name.value || '')

const priceListProps = computed(() => ({
  chargeTotal: new Money(currentPrice.value?.chargeTotal),
  convenienceFee: new Money(currentPrice.value?.feesForCards),
  salesTax: new Money(currentPrice.value?.salesTax),
  showSalesTax: (currentPrice.value?.salesTax.value ?? 0) > 0,
}))

const handleClick = () => {
  if (props.onClick) props.onClick()
  else navigateBack()
}
</script>
