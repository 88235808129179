<template>
  <div class="the-toaster">
    <TransitionGroup mode="out-in" name="slide-in" tag="ul">
      <li v-for="toast in toasts.slice(0, 5)" :key="toast.id">
        <TheToasterToast v-bind="toast" />
      </li>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
const { toasts } = useToaster()
</script>

<style lang="scss" scoped>
.the-toaster {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 2100;
  width: 100vw;
  max-width: $main-content-width;
  padding: spacing('xs');
  transform: translateX(-50%);

  li {
    position: relative;

    + li {
      margin-top: spacing('xs');
    }
  }

  .slide-in {
    &-move,
    &-enter-active,
    &-leave-active {
      @include transition-default(opacity, transform);

      z-index: -1;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(-5rem);
    }
  }
}
</style>
