import RequestRepeatError from './RequestRepeatError'

export const REPEAT_FULFILLED_ERROR_MESSAGE = 'Repeat condition fulfilled'

export default class RequestRepeatFullfilledError extends RequestRepeatError {
  constructor() {
    super(REPEAT_FULFILLED_ERROR_MESSAGE)
    this.name = 'RequestRepeatFullfilled'
  }
}
