import type { TheToasterToastProps } from '~/features/TheToaster/TheToasterToast.vue'

export type Toast = TheToasterToastProps

export type ToastOptions = Omit<Toast, 'id'>

type ToastError = {
  error: unknown
  options?: Partial<ToastOptions>
  prefix: string
}

type ToastErrorHandler = (
  errorMessage: string,
) => Partial<ToastOptions> | undefined | void

type ToastErrorConfig = {
  fallback?: ToastOptions
  handlers?: ToastErrorHandler[]
}
const toasts = ref<Toast[]>([])

const isAlreadyPresent = (id: string) =>
  toasts.value.some((toast) => toast.id === id)

export const useToaster = () => {
  const { getTranslation, t } = useI18nUtils()

  const DEFAULT_ERROR_TOAST: ToastOptions = {
    headline: t('common.toast.error.general.headline'),
    message: t('common.toast.error.general.message'),
    type: 'danger',
  }

  const addToast = (
    options: ToastOptions,
    id: string = crypto.randomUUID(),
  ) => {
    if (isAlreadyPresent(id)) return

    toasts.value.push({ id, ...options })
  }

  const addToastError = (
    { error, options = {}, prefix }: ToastError,
    { fallback = DEFAULT_ERROR_TOAST, handlers = [] }: ToastErrorConfig = {},
  ) => {
    if (!isApolloError(error)) {
      return addToast(fallback)
    }

    const [{ message: firstErrorMessage }] = error.graphQLErrors
    const { headline, message } = getTranslatedErrorParts(
      firstErrorMessage,
      prefix,
    )
    const toastOptions: ToastOptions = {
      headline: headline ?? undefined,
      message: message ?? fallback.message,
      type: 'danger',
      ...options,
    }

    if (handlers?.length) {
      let customOptions: ReturnType<ToastErrorHandler>
      for (const handler of handlers) {
        customOptions = handler(firstErrorMessage)
        if (customOptions) break
      }

      if (customOptions) {
        return addToast({
          ...toastOptions,
          ...customOptions,
        })
      }
    }

    return addToast(message ? toastOptions : fallback)
  }

  const getTranslatedErrorParts = (error: string, prefix?: string) => {
    const errorPath = normalizeKey(`${prefix}.${error}`)

    return {
      headline: getTranslation({ key: `${errorPath}.headline` }),
      message: getTranslation({ key: `${errorPath}.message` }),
    }
  }

  const removeAllToasts = () => {
    toasts.value = []
  }

  const removeAllToastsExcept = (ids: string[]) => {
    if (!ids.length) return removeAllToasts()
    toasts.value = toasts.value.filter((toast) => ids.includes(toast.id))
  }

  const removeToast = (id: string) => {
    const index = toasts.value.findIndex((toast) => toast.id === id)
    if (index < 0) return

    toasts.value.splice(index, 1)
  }

  return {
    addToast,
    addToastError,
    removeAllToasts,
    removeAllToastsExcept,
    removeToast,
    toasts,
  }
}
