import { nuxtAppContext } from '@lottocom/frontend-components'

import { NuxtImg, NuxtLink } from '#components'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('NuxtImg', NuxtImg)
  nuxtApp.vueApp.component('NuxtLink', NuxtLink)

  nuxtApp.vueApp.provide(nuxtAppContext, {
    useId: () => useId(),
  })
})
