import { EventEmitter } from 'events'

export enum BroadcastMessage {
  ClearScratchcardOrders = 'event:clear:scratchcard:orders',
  ClearSingleOrders = 'event:clear:single:orders',
  ClearSubscriptionOrders = 'event:clear:subscription:orders',
  ContextChange = 'event:context:change',
}

class Broadcaster extends EventEmitter {
  private channel

  constructor() {
    super()
    if (instance) throw new Error('Only one instance allowed')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    try {
      this.channel = new BroadcastChannel('ltc-channel')
      this.channel.onmessage = ({ data }) => {
        self.emit(data)
      }
    } catch (_) {
      this.close()
    }
  }

  close() {
    this.channel?.close()
  }

  override on(
    eventName: string | symbol,
    listener: (...args: any[]) => void,
  ): this {
    if (process.server) return this
    super.on(eventName, listener)
    return this
  }

  postMessage(message: BroadcastMessage) {
    if (!this.channel) return
    this.channel.postMessage(message)
  }
}

let instance: Maybe<Broadcaster>

const broadcasterInstance = new Broadcaster()

export default broadcasterInstance
