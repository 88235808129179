<template>
  <div>
    <button
      class="state-switch__button flex items-center h-xl"
      type="button"
      @click="isStateSwitchModalVisible = true"
    >
      <LcIcon class="mr-2xs" name="location" />
      <strong>{{ licensedTerritory.replace('US-', '') }}</strong>
      <LcIcon name="chevron-down" />
    </button>

    <TheHeaderStateSwitchModal v-model:is-visible="isStateSwitchModalVisible" />
    <TheHeaderStateSwitchRedirectModal
      v-model:is-visible="isStateSwitchRedirectModalVisible"
    />
  </div>
</template>

<script lang="ts" setup>
import { LcIcon } from '@lottocom/frontend-components'

const { isStateSwitchRedirectModalVisible, licensedTerritory } =
  useStateSwitch(true)

const isStateSwitchModalVisible = ref(false)
</script>

<style lang="scss" scoped>
.state-switch {
  &__button {
    @include hover-state {
      color: color('primary500');
    }

    cursor: pointer;
  }
}
</style>
