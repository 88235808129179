import priceCalculation from '@lottocom/price-calculation'

import {
  type LotteryQuery,
  type LotterySelectedAddOn,
  type LotterySelectedPerPlayAddOn,
  LotteryTicketType,
  LotteryType,
  type Stake,
} from '~/@types/generated/backend/graphql-schema-types'
import { cache } from '~/apollo/local/cache'
import { PersistedState } from '~/apollo/local/types'
import { ProductType } from '~/composables/checkout'
import type {
  LotterySelectedNumbers,
  LotterySelectedPerPlayAddOns,
  LotterySelectedStakes,
} from '~/features/Lottery/apollo/cache'

export type PlaySlipField = {
  name: string
  selected: number[]
}

export type PlaySlipPlay = {
  fields: PlaySlipField[]
  id: string
  isComplete: boolean
  isDirty: boolean
  perPlayAddOn: Maybe<LotterySelectedPerPlayAddOn>
}

type PlaySlipFieldConfig =
  LotteryQuery['lottery']['currentConfig']['numbers'][0]

export const usePlaySlip = (productName?: string) => {
  const { createAddToCartEvent, createEcommerceResetEvent } = useTracking()
  const licensedTerritory = useLicensedTerritory({ raw: true })
  const { params, query } = useRoute()
  const visiblePlayId = useState('visiblePlayId', () => 'play-1')

  const lotteryId = getLotteryIdInCorrectCase(
    productName || String(params.lotteryId || query?.p),
  )
  if (!lotteryId)
    throw new Error(
      'usePlaySlip composable can only be used in valid lottery routes',
    )

  const queryVariables = {
    licensedTerritory,
    lotteryId,
  }
  const queryResult = useLotteryQuery(queryVariables)

  const addOns = computed(() => config.value?.addOns ?? [])
  const afterPurchaseAddOns = computed(
    () => config.value?.afterPurchaseAddOns ?? [],
  )
  const completePlays = computed(() =>
    plays.value.filter((play) => play.isComplete),
  )
  const config = computed(
    () => queryResult.result.value?.lottery.currentConfig ?? null,
  )
  const confirmedPlays = computed(
    () => queryResult.result.value?.lottery.confirmedPlays ?? [],
  )
  const currentPrice = computed(() => {
    if (!config.value || !priceList.value) return null
    const { numberOfDraws, stakes, version } = config.value

    const preparedAddOns = addOns.value
      .map((addOn) => {
        const value =
          selectedAddOns.value.find(
            (selectedAddOn) => selectedAddOn.name === addOn.name,
          )?.value ?? ''

        return {
          ...addOn,
          value,
        }
      })
      .filter((addon) => addon.value)
    try {
      // eslint-disable-next-line import/no-named-as-default-member
      return priceCalculation.lottery.calculatePrices(
        {
          addOns: preparedAddOns,
          configVersion: version,
          lotteryId,
          numberOfDraws: numberOfDraws[0],
          plays: plays.value
            .filter((play) => play.isComplete)
            .map((play) => {
              const playIndex = plays.value.findIndex(
                (_play) => play.id === _play.id,
              )

              return {
                addOns: [selectedPerPlayAddOns.value[playIndex]].filter(
                  isTruthy,
                ),
                fields: plays.value[playIndex].fields,
                stake: {
                  price: selectedStakes.value[playIndex] ?? stakes[0].price,
                },
              }
            }),
        },
        {
          ...priceList.value,
          currency: 'USD',
        },
      )
    } catch (_) {}
  })
  const fieldConfig = computed(() => config.value?.numbers ?? [])
  const firstIncompletePlayId = computed(
    () =>
      plays.value.find(({ isComplete }) => !isComplete)?.id ??
      plays.value.at(-1)?.id ??
      'play-1',
  )
  const hasDirtyPlays = computed(() =>
    plays.value.some(({ isDirty }) => isDirty),
  )
  const isFixedStake = computed(() => config.value?.stakes.length === 1)
  const isNumberLottery = computed(
    () => lotteryType.value === LotteryType.NumberLottery,
  )
  const isPickLottery = computed(
    () => lotteryType.value === LotteryType.PickLottery,
  )
  const isSubscriptionAllowed = computed(
    () => !!config.value?.subscriptionsAllowed,
  )
  const lastEditedPlayId = computed(
    () => plays.value.filter(({ isDirty }) => isDirty).at(-1)?.id ?? 'play-1',
  )
  const lotteryType = computed(() => config.value?.type ?? null)
  const maxAllowedPlays = computed(() => config.value?.allowedPlays.at(-1) ?? 1)
  const perPlayAddOn = computed(() => addOns.value.find(isPerPlay))
  const plays = computed(() =>
    selectedNumbers.value.map<PlaySlipPlay>((play, index) => {
      const isFieldComplete = fieldConfig.value.every(
        ({ ignoredForAddOns, selectableMax }, fieldIndex) =>
          play[fieldIndex]?.length >= selectableMax ||
          ignoredForAddOns.some(
            ({ value }) => value === selectedPerPlayAddOns.value[index].value,
          ),
      )

      const isPlayConfirmed =
        !isPickLottery.value ||
        confirmedPlays.value.findIndex((play, i) => play && index === i) !== -1

      const isComplete = (isFieldComplete && isPlayConfirmed) ?? false

      const _play = {
        fields:
          fieldConfig.value.map(({ name }, fieldIndex) => ({
            name,
            selected: play[fieldIndex] ?? [],
          })) ?? [],
        id: `play-${index + 1}`,
        isComplete,
        isDirty: play.some((field) => field.length),
        perPlayAddOn: selectedPerPlayAddOns.value[index] ?? null,
      }
      // cross check with pricecalculation library for pick lotteries
      if (isPickLottery.value && _play.isComplete) {
        // eslint-disable-next-line import/no-named-as-default-member
        _play.isComplete = priceCalculation.lottery.validatePlay(
          {
            addOns: [_play.perPlayAddOn],
            fields: _play.fields,
            stake: { price: selectedStakes.value[index] as string },
          },
          lotteryId!,
        )
      }
      return _play
    }),
  )
  const priceList = computed(
    () => queryResult.result.value?.lottery.priceList ?? null,
  )
  const properties = computed(
    () => queryResult.result.value?.lottery.properties ?? [],
  )
  const quickPickConfig = computed(() => config.value?.quickPick ?? null)
  const selectedPerPlayAddOns = computed(
    () => queryResult.result.value?.lottery.selectedPerPlayAddOns ?? [],
  )
  const selectedAddOns = computed(
    () => queryResult.result.value?.lottery.selectedAddOns ?? [],
  )
  const selectedNumbers = computed(
    () => queryResult.result.value?.lottery.selectedNumbers ?? [],
  )
  const selectedStakes = computed(
    () => queryResult.result.value?.lottery.selectedStakes ?? [],
  )
  const ticketType = computed(
    () =>
      queryResult.result.value?.lottery.ticketType ?? LotteryTicketType.Single,
  )

  const deletePlay = (playId: string) => {
    setSelectedNumbersForPlay(
      playId,
      fieldConfig.value.map<number[]>(() => []),
    )

    if (!config.value || !isPickLottery.value) return

    setSelectedStake(getInitialStake(config.value), playId)
    removeConfirmedPlay(playId)
  }

  const fillFieldWithQuickPick = (
    playId: string,
    { max, min, selectableMax }: PlaySlipFieldConfig,
    fieldIndex: number,
  ) => {
    const play = plays.value.find(({ id }) => id === playId)
    const numbers = new Set(play?.fields[fieldIndex].selected)
    if (numbers.size >= selectableMax) numbers.clear()

    while (numbers.size < selectableMax) {
      numbers.add(getRandomNumber(min, max))
    }

    return [...numbers].sort((a, b) => a - b)
  }

  const fillPlaySlipWithQuickPick = (playAmount?: Maybe<number>) => {
    const updatedAddOns = selectedAddOns.value.map((addOn) => {
      const quickPickAddOn = quickPickConfig.value?.addOns.find(
        ({ name }) => name === addOn.name,
      )
      return {
        ...addOn,
        value: quickPickAddOn ? quickPickAddOn.value : addOn.value,
      }
    })
    setSelectedAddOns(updatedAddOns)

    const playsWithQuickPickAmount =
      playAmount ?? quickPickConfig.value?.plays ?? 0
    const updatedPlays = plays.value.map((play, playIndex) =>
      fieldConfig.value.map((field, fieldIndex) =>
        playIndex + 1 <= playsWithQuickPickAmount
          ? fillFieldWithQuickPick(play.id, field, fieldIndex)
          : [],
      ),
    )
    setSelectedNumbers(updatedPlays)

    return playsWithQuickPickAmount
  }

  const fillPlaysWithQuickPick = (playIds: string[]) => {
    const updatedPlays = plays.value.map((play) => {
      if (!playIds.includes(play.id)) {
        return play.fields.map(({ selected }) => selected)
      }

      return fieldConfig.value.map((field, index) =>
        fillFieldWithQuickPick(play.id, field, index),
      )
    })

    setSelectedNumbers(updatedPlays)
  }

  const getPlayIndex = (playId: string) =>
    plays.value.findIndex(({ id }) => id === playId)

  const reset = () => {
    cache.deleteCache(PersistedState.LotteryConfirmedPlays, queryVariables)
    cache.deleteCache(PersistedState.LotterySelectedAddOns, queryVariables)
    cache.deleteCache(PersistedState.LotterySelectedNumbers, queryVariables)
    cache.deleteCache(
      PersistedState.LotterySelectedPerPlayAddOns,
      queryVariables,
    )
    cache.deleteCache(PersistedState.LotterySelectedStakes, queryVariables)
    cache.deleteCache(PersistedState.LotteryTicketType, queryVariables)

    useApollo().clients.default.cache.evict({
      fieldName: 'lottery',
      id: 'ROOT_QUERY',
    })
  }

  const setSelectedAddOn = (
    name: LotterySelectedAddOn['name'],
    value: LotterySelectedAddOn['value'],
  ) => {
    let updatedAddOns = selectedAddOns.value.map((addOn) => ({ ...addOn }))

    if (!updatedAddOns.find((addOn) => addOn.name === name)) {
      updatedAddOns = addOns.value
        .filter(isNotPerPlay)
        .map<LotterySelectedAddOn>((addOn) => {
          const selectedAddOn = selectedAddOns.value?.find(
            ({ name }) => name === addOn.name,
          )
          return {
            name: addOn.name,
            value: selectedAddOn ? selectedAddOn.value : '',
          }
        })
    }

    const addOnIndex = updatedAddOns.findIndex((addOn) => addOn.name === name)
    updatedAddOns[addOnIndex].value = value
    setSelectedAddOns(updatedAddOns)
  }

  const setSelectedAddOns = (value: LotterySelectedAddOn[]) => {
    cache.updateCache(
      PersistedState.LotterySelectedAddOns,
      value,
      queryVariables,
    )
  }

  const setSelectedPerPlayAddOn = (
    updatedAddOn: LotterySelectedPerPlayAddOn,
    playId: string,
  ) => {
    const playIndex = getPlayIndex(playId)
    if (playIndex < 0) return

    const updatedPerPlayAddOns = selectedPerPlayAddOns.value.map(
      (addOn, addOnIndex) => (addOnIndex !== playIndex ? addOn : updatedAddOn),
    )

    setSelectedPerPlayAddOns(updatedPerPlayAddOns)
  }

  const setSelectedPerPlayAddOns = (value: LotterySelectedPerPlayAddOns) => {
    cache.updateCache(
      PersistedState.LotterySelectedPerPlayAddOns,
      value,
      queryVariables,
    )
  }

  const setSelectedNumbersForField = (
    playId: string,
    fieldIndex: number,
    value: number[],
  ) => {
    const playIndex = getPlayIndex(playId)
    if (playIndex < 0) return

    const updatedNumbers = selectedNumbers.value.map((play, _playIndex) =>
      play.map((field, _fieldIndex) =>
        playIndex === _playIndex && fieldIndex === _fieldIndex ? value : field,
      ),
    )
    setSelectedNumbers(updatedNumbers)
  }

  const setSelectedNumbersForPlay = (playId: string, value: number[][]) => {
    const playIndex = getPlayIndex(playId)
    if (playIndex < 0) return
    const updatedNumbers = selectedNumbers.value.map((play, _playIndex) =>
      playIndex === _playIndex ? value : play,
    )
    setSelectedNumbers(updatedNumbers)
  }

  const setSelectedNumbers = (value: LotterySelectedNumbers) => {
    cache.updateCache(
      PersistedState.LotterySelectedNumbers,
      value,
      queryVariables,
    )
  }

  const setSelectedStake = (value: Stake['price'], playId?: string) => {
    const playIndex = playId ? getPlayIndex(playId) : 0

    const updatedStakes = selectedStakes.value.map((stake, stakeIndex) => {
      if (stakeIndex !== playIndex) return stake

      return stakeIndex !== playIndex ? stake : value
    })

    setSelectedStakes(updatedStakes)
  }

  const setSelectedStakes = (value: LotterySelectedStakes) => {
    cache.updateCache(
      PersistedState.LotterySelectedStakes,
      value,
      queryVariables,
    )
  }

  const setTicketType = (value: LotteryTicketType) => {
    cache.updateCache(PersistedState.LotteryTicketType, value, queryVariables)
  }

  const setConfirmedPlay = (playId: string) => {
    const playIndex = getPlayIndex(playId)

    const updatedPlays = confirmedPlays.value.map((confirmed, idx) =>
      idx === playIndex ? true : confirmed,
    )

    cache.updateCache(
      PersistedState.LotteryConfirmedPlays,
      updatedPlays,
      queryVariables,
    )
  }

  const removeConfirmedPlay = (playId: string) => {
    const playIndex = getPlayIndex(playId)
    const updatedConfirmedPlays = confirmedPlays.value.map(
      (confirmed, index) => (playIndex === index ? false : confirmed),
    )

    cache.updateCache(
      PersistedState.LotteryConfirmedPlays,
      updatedConfirmedPlays,
      queryVariables,
    )
  }

  const storePlaySlip = async () => {
    if (!useAuth().isLoggedIn.value)
      navigateTo({
        path: '/login',
        query: { redirect: useRoute().fullPath },
        replace: true,
      })
    if (!config.value) return

    const { numberOfDraws, stakes, version } = config.value
    const { coupon } = useCoupon()
    const { mutate } = useStorePlaySlipMutation()

    const result = await mutate({
      input: {
        addOns: selectedAddOns.value.filter((addOn) => addOn.value),
        bonusCode: coupon.value?.info.code,
        configVersion: version,
        licensedTerritory,
        lotteryId,
        numberOfDraws: numberOfDraws[0],
        plays: plays.value
          .filter((play) => play.isComplete)
          .map((play) => {
            const playIndex = plays.value.findIndex(
              (_play) => play.id === _play.id,
            )

            const selectedPerPlayAddOn = selectedPerPlayAddOns.value[playIndex]

            return {
              addOns: selectedPerPlayAddOn ? [selectedPerPlayAddOn] : [],
              fields: plays.value[playIndex].fields.map((field) => {
                const isIgnored = config.value?.numbers
                  .find((number) => number.name === field.name)
                  ?.ignoredForAddOns.some(
                    (addOn) => addOn.value === selectedPerPlayAddOn.value,
                  )

                return {
                  name: field.name,
                  selected: isIgnored ? [] : field.selected,
                }
              }),
              stake: {
                price: selectedStakes.value[playIndex] ?? stakes[0].price,
              },
            }
          }),
        subscription: ticketType.value === LotteryTicketType.Subscription,
      },
    })
    if (!result?.data) return
    // TODO restriction handling - MIRA-7718 has to be done before
    useCachedProduct().setProductId(result.data.storePlaySlip.id)
    createEcommerceResetEvent()
    createAddToCartEvent(ProductType.Lottery)
  }

  return {
    addOns,
    afterPurchaseAddOns,
    completePlays,
    config,
    confirmedPlays,
    currentPrice,
    deletePlay,
    fieldConfig,
    fillPlaySlipWithQuickPick,
    fillPlaysWithQuickPick,
    firstIncompletePlayId,
    getPlayIndex,
    hasDirtyPlays,
    isFixedStake,
    isNumberLottery,
    isPickLottery,
    isSubscriptionAllowed,
    lastEditedPlayId,
    licensedTerritory,
    lotteryId,
    lotteryType,
    maxAllowedPlays,
    perPlayAddOn,
    plays,
    priceList,
    properties,
    removeConfirmedPlay,
    reset,
    selectedAddOns,
    selectedPerPlayAddOns,
    selectedStakes,
    setConfirmedPlay,
    setSelectedAddOn,
    setSelectedAddOns,
    setSelectedNumbersForField,
    setSelectedNumbersForPlay,
    setSelectedPerPlayAddOn,
    setSelectedStake,
    setTicketType,
    storePlaySlip,
    ticketType,
    visiblePlayId,
  }
}
