import type {
  CmsFooterQuery,
  CmsPaymentIconsQuery,
  Page,
  Seo,
  TrustCertificatesCollection,
} from '~/@types/generated/cms/graphql-schema-types'
import type {
  CustomFaqLotteries,
  CustomFaqScratchcards,
} from '~/modules/contentful/builder'
import { ContentType } from '~/utils/contentful'

const DAYS_UNTIL_EXPIRATION = 7

const isExpired = (expirationDate: number) =>
  new Date(expirationDate).getTime() < Date.now()

const getExpirationDate = () => {
  const now = new Date()
  return now.setDate(now.getDate() + DAYS_UNTIL_EXPIRATION)
}

export const useContentfulContent = () => {
  const nuxtApp = useNuxtApp()
  const licensedTerritory = useLicensedTerritory()

  const getCachedData = (key: string) => {
    const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

    if (!data || isExpired(data.expirationDate)) return
    return data
  }

  const transform = <T>(input: T) => ({
    ...input,
    expirationDate: getExpirationDate(),
  })

  const getContentByUrl = (path: string, url: string) =>
    useAsyncData<Page>(path, () =>
      queryContent<Page>(path)
        .where({
          url: { $eq: url },
        })
        .find()
        .then((response) => {
          const filteredResponse = response.filter(
            (doc) =>
              doc.contentfulMetadata.tags.some(
                (tag) => tag?.id === `LT-${licensedTerritory.toUpperCase()}`,
              ) || (doc as Page).contentfulMetadata.tags.length === 0,
          )
          const ltDoc = filteredResponse.find(
            (doc) => doc.contentfulMetadata.tags.length,
          )
          if (ltDoc) return ltDoc
          return filteredResponse[0]
        }),
    )

  const getContentByLotteryId = (path: string, id: string) =>
    useAsyncData<CustomFaqLotteries>(path, () =>
      queryContent<CustomFaqLotteries>(path)
        .where({
          lotteryId: { $eq: id },
        })
        .find()
        .then((response) => {
          const filteredResponse = response.filter(
            (doc) =>
              doc.contentfulMetadata.tags.some(
                (tag) => tag?.id === `LT-${licensedTerritory.toUpperCase()}`,
              ) || (doc as Page).contentfulMetadata.tags.length === 0,
          )
          const ltDoc = filteredResponse.find(
            (doc) => doc.contentfulMetadata.tags.length,
          )
          if (ltDoc) return ltDoc
          return filteredResponse[0]
        }),
    )

  const getContentBySlug = (path: string, slug: string) =>
    useAsyncData<CustomFaqScratchcards>(path, () =>
      queryContent<CustomFaqScratchcards>(path)
        .where({
          scratchcardSlug: { $eq: slug },
        })
        .find()
        .then((response) => {
          const filteredResponse = response.filter(
            (doc) =>
              doc.contentfulMetadata.tags.some(
                (tag) => tag?.id === `LT-${licensedTerritory.toUpperCase()}`,
              ) || (doc as Page).contentfulMetadata.tags.length === 0,
          )
          const ltDoc = filteredResponse.find(
            (doc) => doc.contentfulMetadata.tags.length,
          )
          if (ltDoc) return ltDoc
          return filteredResponse[0]
        }),
    )

  const getContentByPath = <ReturnType>(path: string) =>
    useAsyncData<ReturnType>(
      path,
      () => queryContent<ReturnType>(path).findOne(),
      {
        getCachedData,
        transform: transform<ReturnType>,
      },
    )

  const getFaq = (id: string) => {
    const BASE_PATH = '/contentful/faq/lottery'

    return getContentByLotteryId(BASE_PATH, id)
  }

  const getFaqScratchcard = (url: string) => {
    const BASE_PATH = '/contentful/faq/scratchcard'

    return getContentBySlug(BASE_PATH, url)
  }

  const getPage = (url: string, type: ContentType) => {
    const BASE_PATH = `/contentful/${type}`
    return getContentByUrl(BASE_PATH, url)
  }

  const getPrizes = (url: string) => getPage(url, ContentType.Prizes)
  const getRules = (url: string) => getPage(url, ContentType.Rules)

  const getFooter = () =>
    getContentByPath<CmsFooterQuery['footerCollection']>(
      `/contentful/footer/${licensedTerritory}`,
    )

  const getPaymentIcons = () =>
    getContentByPath<CmsPaymentIconsQuery['paymentIconCollection']>(
      '/contentful/icons/payment',
    )

  const getSeo = () => getContentByPath<{ items: Seo[] }>('/contentful/seo')

  const getTrustCertificatesCollection = () =>
    getContentByPath<TrustCertificatesCollection>(
      `/contentful/trust-certificates/${licensedTerritory}`,
    )

  return {
    getFaq,
    getFaqScratchcard,
    getFooter,
    getPaymentIcons,
    getPrizes,
    getRules,
    getSeo,
    getTrustCertificatesCollection,
  }
}
