import {
  REDIRECT_COOKIE_KEY,
  RedirectType,
  type StateSwitchOption,
  stateSwitchQueryParam,
  stateSwitchRedirectResultQueryParam,
  stateSwitchRedirectTypeQueryParam,
} from '~/features/TheHeader/types'

export const useStateSwitch = (handleQueryParams?: boolean) => {
  const { t } = useI18n()
  const { query } = useRoute()
  const { currentRoute, replace } = useRouter()
  const { removeQueryParams } = useRouterUtils()
  const { hiddenLicensedTerritories, licensedTerritories, licensedTerritory } =
    useRuntimeConfig().public
  const { addToast } = useToaster()

  const isStateSwitchRedirectModalVisible = ref(false)

  const toStateSwitchOptions = (value: string): StateSwitchOption => {
    const [id, uri] = value.split(':')

    const state = t(`licensedterritory.${id}`)

    return {
      href: `https://${uri}${currentRoute.value.path}?ts=${id}`,
      id,
      selected: id === licensedTerritory,
      state,
      title: t('stateswitch.link.title', {
        state,
      }),
    }
  }

  const filterHiddenLicensedTerritories = (value: string) =>
    !(hiddenLicensedTerritories.split(',') ?? []).includes(
      value.substring(0, 5),
    )

  const options = computed<StateSwitchOption[]>(() =>
    licensedTerritories
      .split(',')
      .filter(filterHiddenLicensedTerritories)
      .map(toStateSwitchOptions),
  )

  onMounted(() => {
    if (!handleQueryParams) return

    let queryParams = []

    if (query[stateSwitchQueryParam]) {
      addToast({
        headline: t('stateswitch.toast.headline', {
          state: t(`licensedterritory.${licensedTerritory}`),
        }),
        message: t('stateswitch.toast.message'),
        type: 'info',
      })

      queryParams.push(stateSwitchQueryParam)
    }

    if (query[stateSwitchRedirectTypeQueryParam]) {
      const cookie = useCookie<{
        isStateSwitchRedirectModalVisible: Maybe<boolean>
        redirectResult: Maybe<string>
      }>(REDIRECT_COOKIE_KEY, {
        default: () => ({
          isStateSwitchRedirectModalVisible: null,
          redirectResult: null,
        }),
      })

      queryParams = [
        stateSwitchRedirectResultQueryParam,
        stateSwitchRedirectTypeQueryParam,
        ...queryParams,
      ]

      if (
        query[stateSwitchRedirectTypeQueryParam] === RedirectType.Cloudflare
      ) {
        isStateSwitchRedirectModalVisible.value =
          cookie.value.isStateSwitchRedirectModalVisible ?? true
        cookie.value = {
          isStateSwitchRedirectModalVisible: false,
          redirectResult: String(query[stateSwitchRedirectResultQueryParam]),
        }
      }
    }

    if (queryParams.length) {
      const replaceQuery = removeQueryParams(query, queryParams)
      replace({ query: replaceQuery })
    }
  })

  return {
    isStateSwitchRedirectModalVisible,
    licensedTerritory,
    options,
  }
}
