import { ApolloLink } from '@apollo/client/core'

import RequestRepeatFullfilledError from '~/apollo/errors/RequestRepeatFullfilledError'
import RequestRepeatNotFullfilledError from '~/apollo/errors/RequestRepeatNotFullfilled'

export type RepeatOptions = {
  repeatCountReached?: boolean
  repeatOptions: {
    delay?: number
    errorOnNotFulfilled?: boolean
    fixedDelay?: boolean
    repeatCount?: number
    repeatIf(response: VariousResult<any>): boolean
  }
}

export const repeatableLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const { repeatCountReached, repeatOptions } =
      operation.getContext() as RepeatOptions

    if (repeatCountReached && repeatOptions?.errorOnNotFulfilled) {
      throw new RequestRepeatNotFullfilledError()
    }
    if (!repeatCountReached && repeatOptions?.repeatIf(response)) {
      throw new RequestRepeatFullfilledError()
    }

    return response
  }),
)
