type GetTranslationOptions = {
  fallback?: string
  key: string
  values?: Record<string, unknown>
}

export const useI18nUtils = () => {
  const { t, te } = useI18n()

  const getTranslation = ({
    fallback = '',
    key,
    values = {},
  }: GetTranslationOptions) => (te(key) ? t(key, values) : fallback)

  return { getTranslation, t, te }
}
