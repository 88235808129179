import type { IncomingMessage, ServerResponse } from 'node:http'

import type { Extras } from '@sentry/types'
import type { SeverityLevel } from '@sentry/vue'
import type { LevelWithSilent, Logger, LoggerOptions } from 'pino'
import type { HttpLogger } from 'pino-http'
import type pretty from 'pino-pretty'

export type CustomLogLevel = {
  (
    _: IncomingMessage,
    res: ServerResponse<IncomingMessage>,
    err: Error | undefined,
  ): LevelWithSilent
}

export type CreateLoggerOptions = LoggerOptions & {
  loggerName?: string
  serviceName?: string
}

export type LogSentry = {
  (
    error: unknown,
    level: SeverityLevel,
    component: string,
    type: string,
    message: string,
    extra?: Extras,
  ): void
}

export type LottoFormat = {
  (
    opts?: CreateLoggerOptions,
  ): [LoggerOptions, pretty.PrettyStream] | [LoggerOptions]
}

export enum LottoLogLevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export type NitroLogger = {
  $nitroLogger: {
    http: HttpLogger
    logger: Logger
  }
}
