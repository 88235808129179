import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const CUSTOM_PLAY_NOW_ROUTES = {
  Lotteries: { name: 'play_now_lotteries', path: 'lotteries' },
  Scratchcards: { name: 'play_now_scratchcards', path: 'scratchcards' },
} as const

const checkStateOfTab = (to: RouteLocationNormalized) => {
  const { query } = useRoute()

  if (
    to.name !== CUSTOM_PLAY_NOW_ROUTES.Scratchcards.name ||
    useIsScratchcardEnabled()
  ) {
    return
  }

  return navigateTo({
    path: '/play-now/lotteries',
    query,
  })
}

const PlayNow = () => import('~/custom-pages/play-now/index.vue')

const PlayNowLotteries = () =>
  import('~/custom-pages/play-now/children/lotteries.vue')

const PlayNowScratchcards = () =>
  import('~/custom-pages/play-now/children/scratchcards.vue')

const components = {
  [CUSTOM_PLAY_NOW_ROUTES.Lotteries.name]: PlayNowLotteries,
  [CUSTOM_PLAY_NOW_ROUTES.Scratchcards.name]: PlayNowScratchcards,
}

export const playNowRoutes: RouteRecordRaw = {
  children: [
    {
      components,
      ...CUSTOM_PLAY_NOW_ROUTES.Lotteries,
    },
    {
      components,
      meta: { middleware: checkStateOfTab },
      ...CUSTOM_PLAY_NOW_ROUTES.Scratchcards,
    },
  ],
  component: PlayNow,
  meta: {
    footer: 'condensed',
    header: 'full',
    middleware: ['age-verification', 'self-excluded'],
  },
  path: '/play-now',
  redirect: '/play-now/lotteries',
}
