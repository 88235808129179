<template>
  <LcToast
    class="the-toaster-toast"
    v-bind="{ headline, closable }"
    :variant="type"
    @close="removeToast(id)"
  >
    <p v-if="typeof message === 'string'">{{ message }}</p>

    <p
      v-for="(text, index) in message"
      v-else-if="Array.isArray(message)"
      :key="index"
    >
      {{ text }}
    </p>

    <i18n-t v-else :keypath="message.translationKey" scope="global" tag="div">
      <template
        v-for="(translationVariable, slotName) in message.translationVariables"
        :key="slotName"
        #[slotName]
      >
        <HyperLink
          v-if="isLink(translationVariable)"
          :target="translationVariable.target"
          :to="translationVariable.to"
        >
          {{ translationVariable.text }}
        </HyperLink>

        <p v-else>
          {{ translationVariable.text }}
        </p>
      </template>
    </i18n-t>
  </LcToast>
</template>

<script lang="ts" setup>
import { LcToast, type LcToastVariant } from '@lottocom/frontend-components'

import type { RouteLocationRaw } from '#vue-router'

export type ToastTranslationLink = {
  target?: '_blank' | '_parent' | '_self' | '_top'
  text: string
  to: RouteLocationRaw
  type: 'link'
}

export type ToastTranslationText = {
  text: string
  type: 'text'
}

type ToastTranslationType = ToastTranslationLink | ToastTranslationText

type ToastMessageTranslationVariables = Record<string, ToastTranslationType>

type ToastMessage = {
  translationKey: string
  translationVariables?: ToastMessageTranslationVariables
}

export type TheToasterToastProps = {
  autoClose?: boolean
  closable?: boolean
  headline?: string
  id: string
  message: string[] | ToastMessage | string
  type?: LcToastVariant
}

const props = withDefaults(defineProps<TheToasterToastProps>(), {
  autoClose: true,
  closable: true,
})

const { removeToast } = useToaster()

const isLink = (
  translationVariable: ToastTranslationType,
): translationVariable is ToastTranslationLink =>
  translationVariable.type === 'link'

onMounted(() => {
  if (!props.autoClose) return

  setTimeout(() => {
    removeToast(props.id)
  }, 5000)
})
</script>

<style lang="scss" scoped>
.the-toaster-toast {
  a {
    @include link-default;
  }
}
</style>
