type ZendeskWidget = {
  close: () => void
  hide: () => void
  open: () => void
  prefill: (player: any) => void
}

const settings = {
  webWidget: {
    answerBot: {
      avatar: {
        contactOnlyAfterQuery: true,
        name: { '*': 'Jane' },
      },
      title: { '*': 'Get Help' },
    },
    chat: {
      // suppress: true,
      hideWhenOffline: false,
    },
    color: {
      articleLinks: '#005DCE',
      button: '#FF2457',
      header: '#F2F6FF',

      launcher: '#F2F6FF',

      // This will also update the badge
      launcherText: '#000860',

      resultLists: '#000860',
      theme: '#B1BEE2',
    },
    contactOptions: {
      // enabled: true,
      // contactFormLabel: { '*': 'Leave us a message' },
      // contactButton: { '*': 'Get in touch' },
      // chatLabelOnline: { '*': 'Live Chat' },
      // chatLabelOffline: { '*': 'Chat is unavailable' },
    },
    helpCenter: {
      originalArticleButton: false,
    },
    offset: {
      mobile: {
        vertical: '65px',
      },
      vertical: '65px',
    },
  },
}

export default defineNuxtPlugin(() => {
  const key = useRuntimeConfig().public.zendesk.key
  const { locale } = useNuxtApp().$i18n

  const zendesk: ZendeskWidget = {
    close: (): void => {
      if (!window.zE) return
      window.zE('webWidget', 'close')
    },
    hide: (): void => {
      if (!window.zE) return
      window.zE('webWidget', 'hide')
    },
    open: (): void => {
      if (!window.zE) return
      window.zE('webWidget', 'show')
      window.zE('webWidget', 'toggle')
    },
    prefill: (user: any): void => {
      if (!window.zE) return
      window.zE('webWidget', 'prefill', user)
    },
  }

  const onload = () => {
    Object.defineProperty(zendesk, 'zE', {
      get: () => window.zE,
    })

    window.zESettings = settings

    zendesk.hide()

    if (locale) {
      window.zE('webWidget', 'setLocale', locale)
    }

    window.zE('webWidget:on', 'close', () => {
      zendesk.hide()
    })

    window.zE('webWidget:on', 'userEvent', (e: any) => {
      if (e.action === 'Web Widget Minimised') {
        zendesk.hide()
      }
    })
  }

  try {
    delete window.zE

    loadScript({
      options: { async: true, id: 'ze-snippet', onload },
      src: `https://static.zdassets.com/ekr/snippet.js?key=${key}`,
    })
  } catch (_) {}

  return {
    provide: {
      zendesk,
    },
  }
})
