import type { ApolloLink } from '@apollo/client'
import { split } from '@apollo/client/core'
import { getMainDefinition } from '@apollo/client/utilities'

export const splitTransferLinks = (wsLink: ApolloLink, httpLink: ApolloLink) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )
