import { ProductType } from '~/composables/checkout'
import { BroadcastMessage } from '~/utils/broadcaster'

export const useMyOrdersCache = () => {
  const apollo = useApollo()

  const clearMyOrders = ({
    broadcast = false,
    productType,
  }: { broadcast?: boolean; productType?: ProductType } = {}) => {
    switch (productType) {
      case ProductType.Lottery:
        return clearSingleOrders(broadcast)
      case ProductType.Scratchcard:
        return clearScratchcards(broadcast)
      case ProductType.LotterySubscription:
        return clearSubscriptions(broadcast)
      default:
        clearSingleOrders(broadcast)
        clearScratchcards(broadcast)
        clearSubscriptions(broadcast)
    }
  }

  const clearScratchcards = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearScratchcardOrders)

    apollo.clients.default.cache.evict({
      fieldName: 'orders',
      id: 'ROOT_QUERY',
    })
  }

  const clearSingleOrders = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearSingleOrders)

    apollo.clients.default.cache.evict({
      fieldName: 'playSlips',
      id: 'ROOT_QUERY',
    })
  }

  const clearSubscriptions = (broadcast = false) => {
    if (broadcast) notifyTabs(BroadcastMessage.ClearSubscriptionOrders)

    apollo.clients.default.cache.evict({
      fieldName: 'subscriptions',
      id: 'ROOT_QUERY',
    })
  }

  const notifyTabs = (message: BroadcastMessage) => {
    broadcaster.postMessage(message)
  }

  return {
    clear: clearMyOrders,
    clearScratchcards,
    clearSingleOrders,
    clearSubscriptions,
  }
}
