import type { Fn } from '@vueuse/shared'

type ComplianceValues = {
  current?: Maybe<string>
  previous?: Maybe<string>
}
type ComplianceIds = {
  deviceProfiling: ComplianceValues
  geoCheck: ComplianceValues
}

export type LocationData = {
  code: number | string
  domain: string
  heading: string
  latitude: string
  locCount: string
  longitude: string
  message: string
  receivedTimestamp: number
  speed: string
  submitterIpAddress: string
  timeStamp: string
}

export type LocationSmartResponse = {
  locationData: LocationData
  requestTime: string
  sessionId: string
  statusCode: string
  statusMsg: 'Error' | 'Success'
  uid: string
}

export const RESET_COMPLIANCE_IDS_TYPES = {
  All: 'all',
  DeviceProfiling: 'deviceProfiling',
  GeoCheck: 'geoCheck',
  None: 'none',
} as const

type ResetComplianceIdType = ObjectValues<typeof RESET_COMPLIANCE_IDS_TYPES>

export enum ComplianceError {
  DeviceProfilingDeniedPermissions = 'compliance.error.deviceprofiling.deniedpermissions',
  DeviceProfilingGeneral = 'compliance.deviceprofiling.error.general',
  GeoCheckDeniedPermissions = 'compliance.geocheck.error.deniedpermissions',
  GeoCheckGeneral = 'compliance.geocheck.error.general',
  GeoCheckWrongLocationData = 'compliance.geocheck.error.wronglocationdata',
}

enum LocationDataCodes {
  DeniedPermissions = 1,
  InvalidLocationData = 2,
  Success = 0,
}

const RESET_DEVICE_COMPLIANCE = [
  RESET_COMPLIANCE_IDS_TYPES.All,
  RESET_COMPLIANCE_IDS_TYPES.DeviceProfiling,
] as string[]

const RESET_GEO_CHECK = [
  RESET_COMPLIANCE_IDS_TYPES.All,
  RESET_COMPLIANCE_IDS_TYPES.GeoCheck,
] as string[]

let cleanupVisibilityHandler: Fn
const complianceIds = reactive<ComplianceIds>({
  deviceProfiling: {
    current: null,
    previous: null,
  },
  geoCheck: {
    current: null,
    previous: null,
  },
})

const error = ref<Maybe<ComplianceError>>(null)
const intervals: Partial<
  Record<'deviceProfilingInterval' | 'geoCheckInterval', Maybe<NodeJS.Timeout>>
> = {}
const ipAddress = ref<Maybe<string>>(null)

const timeouts: Partial<
  Record<
    'deviceProfilingTimeout' | 'geoCheckSuccessTimeout' | 'geoCheckTimeout',
    Maybe<NodeJS.Timeout>
  >
> = {}
export const useCompliance = (options = { stopOnUnmounted: true }) => {
  const { isNativeApp } = useCustomContext()
  const { deviceProfiling: deviceProflingConfig, geoCheck: geoCheckConfig } =
    useRuntimeConfig().public.compliance

  const isDeviceProfilingRunning = ref(false)
  const isDeviceAppProfilingRunning = ref(false)
  const isGeoCheckRunning = ref(false)

  const html5SessionId = computed(() => {
    const { geoCheck } = complianceIds
    return geoCheck.current || geoCheck.previous || undefined
  })

  const isComplianceLoading = computed(
    () =>
      isDeviceAppProfilingRunning.value ||
      isDeviceProfilingRunning.value ||
      isGeoCheckRunning.value,
  )

  const profilingSessionId = computed(() => {
    const { deviceProfiling } = complianceIds
    return deviceProfiling.current || deviceProfiling.previous || ''
  })

  const submitterIPAddress = computed(() => ipAddress.value || undefined)

  const canCheckGeolocation = async (): Promise<boolean> => {
    if (isNativeApp) {
      await useFlutter()?.showLocationPermissionDialog()
      return true
    }
    if (!navigator.permissions) return false
    try {
      const { state } = await navigator.permissions.query({
        name: 'geolocation',
      })
      return state === 'granted'
    } catch (_) {
      return false
    }
  }

  const clearAllTimeouts = () => {
    if (!isNativeApp) {
      clearGeoCheckTimeout()
      clearGeoCheckSuccessTimeout()
      clearDeviceProfilingTimeout()
    }
  }

  const clearDeviceProfilingTimeout = () => {
    if (timeouts.deviceProfilingTimeout) {
      clearTimeout(timeouts.deviceProfilingTimeout)
      timeouts.deviceProfilingTimeout = null
    }
  }

  const clearGeoCheckTimeout = () => {
    if (timeouts.geoCheckTimeout) {
      clearTimeout(timeouts.geoCheckTimeout)
      timeouts.geoCheckTimeout = null
    }
  }

  const clearGeoCheckSuccessTimeout = () => {
    if (timeouts.geoCheckSuccessTimeout) {
      clearTimeout(timeouts.geoCheckSuccessTimeout)
      timeouts.geoCheckSuccessTimeout = null
    }
  }

  const geoCheck = () => {
    isGeoCheckRunning.value = true

    if (complianceIds.geoCheck.current)
      complianceIds.geoCheck.previous = complianceIds.geoCheck.current
    const sessionId = crypto.randomUUID()
    try {
      if (!timeouts.geoCheckTimeout) {
        timeouts.geoCheckTimeout = setTimeout(() => {
          setGeoCheckError()
        }, geoCheckConfig.timeouts.success)
      }

      window.lshtml5(
        sessionId,
        true,
        0,
        geoCheckConfig.timeouts.script,
        (result) => {
          if (result.statusMsg === 'Success') {
            clearGeoCheckTimeout()
            const { code, timeStamp } = result.locationData
            const parsedCode =
              typeof code === 'number' ? code : parseInt(code, 10)
            if (parsedCode === LocationDataCodes.Success) {
              const now = new Date().getTime()
              const locationAgeInSeconds =
                ((parseInt(timeStamp, 10) - now) / 1000) * -1
              if (locationAgeInSeconds < 30) {
                clearGeoCheckTimeout()
                clearGeoCheckSuccessTimeout()
                setGeoCheckResponse(result)
              } else if (!timeouts.geoCheckSuccessTimeout) {
                // id will be used if no updated locationData arrives after timeout
                timeouts.geoCheckSuccessTimeout = setTimeout(() => {
                  setGeoCheckResponse(result)
                  timeouts.geoCheckSuccessTimeout = null
                }, geoCheckConfig.timeouts.outdatedTimestamp)
              }
            }

            if (parsedCode !== LocationDataCodes.Success) {
              let errorMsg = ComplianceError.GeoCheckGeneral
              switch (parsedCode) {
                case LocationDataCodes.DeniedPermissions:
                  errorMsg = ComplianceError.GeoCheckDeniedPermissions
                  break
                case LocationDataCodes.InvalidLocationData:
                  errorMsg = ComplianceError.GeoCheckWrongLocationData
                  break
              }
              setGeoCheckError(errorMsg)
            }
          } else {
            setGeoCheckError()
          }
          isGeoCheckRunning.value = false
        },
        window.location.host,
        geoCheckConfig.watchMode,
        geoCheckConfig.watchDuration,
      )
    } catch (_) {
      setGeoCheckError()
      isGeoCheckRunning.value = false
    }
  }

  const injectDeviceProfiling = () => {
    try {
      if (process.client && !window.tmx_profiling_complete) {
        window.tmx_profiling_complete = (sessionId: string) => {
          clearDeviceProfilingTimeout()
          complianceIds.deviceProfiling.current = sessionId
        }
      }
      if (!isNativeApp && !window.lsdp) {
        // insert needed scripts in browsers
        loadScript({
          options: {
            async: true,
            onload: () => profileDevice(),
          },
          src: '/scripts/toolkit.js',
        })
      } else {
        profileDevice()
      }
    } catch (_) {
      setDeviceProfilingError()
    }
  }

  const injectGeoCheck = () => {
    if (!isNativeApp && !window.lshtml5) {
      try {
        loadScript({
          options: { async: true, onload: () => geoCheck() },
          src: useRuntimeConfig().public.compliance.geoCheck.scriptUrl,
        })
      } catch (_) {
        setGeoCheckError()
      }
    } else {
      geoCheck()
    }
  }

  const profileDevice = () => {
    isDeviceProfilingRunning.value = true

    if (complianceIds.deviceProfiling.current)
      complianceIds.deviceProfiling.previous =
        complianceIds.deviceProfiling.current
    const sessionId = crypto.randomUUID()
    try {
      const { orgId, scriptUrl } =
        useRuntimeConfig().public.compliance.deviceProfiling
      if (!timeouts.deviceProfilingTimeout)
        timeouts.deviceProfilingTimeout = setTimeout(() => {
          resetComplianceIds(RESET_COMPLIANCE_IDS_TYPES.DeviceProfiling)
          stopCompliance()
          error.value = ComplianceError.DeviceProfilingGeneral
        }, deviceProflingConfig.timeout)

      window.lsdp.dp(scriptUrl, orgId, sessionId)
      isDeviceProfilingRunning.value = false
    } catch (_) {
      isDeviceProfilingRunning.value = false
    }
  }

  const profileDeviceApp = async () => {
    isDeviceAppProfilingRunning.value = true

    if (complianceIds.deviceProfiling.current)
      complianceIds.deviceProfiling.previous =
        complianceIds.deviceProfiling.current
    const sessionId = crypto.randomUUID()
    try {
      const result = await useFlutter()?.getLocationSmartProfile(sessionId)
      if (!result) {
        setDeviceProfilingError(ComplianceError.DeviceProfilingGeneral)
      } else {
        const { status, success } = result
        if (success) setDeviceProfilingResponse(sessionId)
        if (!success)
          setDeviceProfilingError(
            status === 'checkout.denied.locationpermissions'
              ? ComplianceError.DeviceProfilingDeniedPermissions
              : ComplianceError.DeviceProfilingGeneral,
          )
      }
      isDeviceAppProfilingRunning.value = false
    } catch (_) {
      setDeviceProfilingError(ComplianceError.DeviceProfilingGeneral)
      isDeviceAppProfilingRunning.value = false
    }
  }

  const resetComplianceIds = (type: ResetComplianceIdType) => {
    if (type === RESET_COMPLIANCE_IDS_TYPES.None) return

    if (RESET_DEVICE_COMPLIANCE.includes(type)) {
      complianceIds.deviceProfiling = {}
    }

    if (!isNativeApp && RESET_GEO_CHECK.includes(type)) {
      complianceIds.geoCheck = {}
    }
  }

  const resetError = () => {
    error.value = null
  }

  const setDeviceProfilingError = (
    _error: ComplianceError = ComplianceError.DeviceProfilingGeneral,
  ) => {
    stopCompliance()
    resetComplianceIds(RESET_COMPLIANCE_IDS_TYPES.DeviceProfiling)
    error.value = _error
  }

  const setDeviceProfilingResponse = (sessionId: string) => {
    complianceIds.deviceProfiling.current = sessionId
    if (isCompliant.value) error.value = null
  }

  const setGeoCheckError = (
    _error: ComplianceError = ComplianceError.GeoCheckGeneral,
  ) => {
    stopCompliance()
    resetComplianceIds(RESET_COMPLIANCE_IDS_TYPES.GeoCheck)
    error.value = _error
  }

  const setGeoCheckResponse = (result: LocationSmartResponse) => {
    complianceIds.geoCheck.current = result.sessionId
    ipAddress.value = result.locationData.submitterIpAddress
    if (isCompliant) error.value = null
  }
  const startIntervals = () => {
    if (!isNativeApp && !intervals.geoCheckInterval) {
      intervals.geoCheckInterval = setInterval(
        () => geoCheck(),
        geoCheckConfig.interval,
      )
    }
    const { app: appInterval, web: webInterval } =
      deviceProflingConfig.intervals
    const interval = isNativeApp ? appInterval : webInterval
    if (!intervals.deviceProfilingInterval) {
      intervals.deviceProfilingInterval = setInterval(
        isNativeApp ? profileDeviceApp : profileDevice,
        interval,
      )
    }
  }
  const stopIntervals = () => {
    if (!isNativeApp) {
      clearInterval(intervals.geoCheckInterval!)
      intervals.geoCheckInterval = null
    }
    clearInterval(intervals.deviceProfilingInterval!)
    intervals.deviceProfilingInterval = null
  }

  const stopCompliance = (
    resetType: ResetComplianceIdType = RESET_COMPLIANCE_IDS_TYPES.All,
  ) => {
    if (cleanupVisibilityHandler) cleanupVisibilityHandler()
    stopIntervals()
    clearAllTimeouts()
    resetComplianceIds(resetType)
  }

  const startCompliance = async () => {
    if (process.server) return
    startIntervals()
    if (isNativeApp) {
      await profileDeviceApp()
    } else {
      injectGeoCheck()
      injectDeviceProfiling()
    }
    cleanupVisibilityHandler = useEventListener(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      'visibilitychange',
      visibilityHandler,
    )
  }

  const visibilityHandler = () => {
    if (document.visibilityState === 'hidden') {
      stopIntervals()
      clearAllTimeouts()
    } else {
      if (!isNativeApp) geoCheck()
      isNativeApp ? profileDeviceApp() : profileDevice()
      startIntervals()
    }
  }

  const isCompliant = computed(() => {
    const { deviceProfiling, geoCheck } = complianceIds

    return (
      (isNativeApp || !!geoCheck.current || !!geoCheck.previous) &&
      (!!deviceProfiling.current || !!deviceProfiling.previous)
    )
  })

  onUnmounted(() => {
    if (!options.stopOnUnmounted) return

    try {
      if (!intervals.deviceProfilingInterval) return // already stopped or not initiated (e.g. if you just use ids in a component)
      stopCompliance()
    } catch (_) {}
  })

  return {
    canCheckGeolocation,
    complianceIds: readonly(complianceIds),
    error,
    html5SessionId,
    ipAddress,
    isComplianceLoading,
    isCompliant,
    profilingSessionId,
    resetError,
    startCompliance,
    stopCompliance,
    submitterIPAddress,
  }
}
