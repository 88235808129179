import { makeVar, type ReactiveVar } from '@apollo/client/core'

import type { CookieRef, NuxtApp } from '#app'
import type { PersistedStates } from '~/apollo/local/types'

export const cache = (() => {
  let nuxt: NuxtApp

  const persistedCache: Record<string, ReactiveVar<any>> = reactive({})

  const setNuxtContext = (nuxtApp: NuxtApp) => {
    nuxt = nuxtApp
  }

  const cacheCreator = <T>(key: string, defaultValue: T) => {
    // only read and parse cookie on server
    // get cookie (init if not present)
    const cookie = getCookie<T>(key, defaultValue)
    const cache = persistedCache[key]
    if (cache) {
      cookie.value = cache()
      return
    }

    persistedCache[key] = makeVar<T>(cookie.value as T)
  }

  const deleteCache = (keyName: PersistedStates, variables?: LooseObject) => {
    const key = keyGenerator(keyName, variables)
    setCookie(key, null)
    if (import.meta.client) delete persistedCache[key]
  }

  const getCookie = <T>(key: string, defaultValue: T) => {
    let cookieValue: CookieRef<T>
    nuxt.runWithContext(() => {
      cookieValue = useCookie(key, { default: () => defaultValue })
    })
    return cookieValue!
  }

  const setCookie = (key: string, value: any) => {
    nuxt.runWithContext(() => {
      useCookie(key).value = value
    })
  }

  const keyGenerator = (key: PersistedStates, variables?: LooseObject) => {
    if (!variables) return key

    const stringifiedVariables = Object.values(variables)
      .map((value) => (typeof value === 'string' ? value.toLowerCase() : value))
      .sort()
      .join('_')
    return `${key}_${stringifiedVariables}`
  }

  const readCache = <T>(
    keyName: PersistedStates,
    value: T,
    variables?: LooseObject,
  ): T => {
    const key = keyGenerator(keyName, variables)
    if (process.server) {
      return getCookie<T>(key, value).value
    }
    cacheCreator(key, value)
    return persistedCache[key]()
  }

  const updateCache = <T>(
    keyName: PersistedStates,
    value: T,
    variables?: LooseObject,
  ) => {
    if (import.meta.server) return
    const key = keyGenerator(keyName, variables)
    cacheCreator(key, value)
    persistedCache[key](value)
  }

  return {
    cacheCreator,
    deleteCache,
    keyGenerator,
    persistedCache,
    readCache,
    setNuxtContext,
    updateCache,
  }
})()
