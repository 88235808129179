<template>
  <div class="flex items-center h-full">
    <template v-if="isLoggedIn && !loading && balance">
      <LcIcon class="mr-2xs" name="account-balance" />
      {{ $t('theheader.balance') }}

      <strong class="ml-xs">
        {{
          $n(balance / 100, {
            style: 'currency',
            currency,
          })
        }}
      </strong>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { LcIcon } from '@lottocom/frontend-components'

type TheHeaderBalanceEmit = {
  (e: 'hasBalance'): void
}

const emit = defineEmits<TheHeaderBalanceEmit>()

const { isLoggedIn } = useAuth()
const { loading, onResult, result } = useAccountQuery({
  enabled: isLoggedIn.value,
  fetchPolicy: 'cache-and-network', // always fetch the latest balance
})

onResult(({ data, loading }) => {
  if (loading) return
  if (data.account.balance.value > 0) {
    emit('hasBalance')
    useState(StateKey.HasBalance, () => true)
  }
})

const balance = computed(() => result.value?.account.balance?.value ?? 0)
const currency = computed(
  () => result.value?.account.balance?.currency ?? 'USD',
)
</script>
