import { createHttpLink, from } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

/*
Hopefully in a future version of nuxtjs/apollo the workaround with plugin/apollo.ts won't be needed anymore for multi environment setups.
Best attempt would be to use browserHttpEndpoint and httpEndpoint in here with values from runtime config
 */
export const createAuthLinks = (nuxtApp: any, uri: string) => {
  const authLink = setContext(({ operationName }, { headers }) => {
    if (operationName !== 'TwoFactorAuthenticationLogin') return { headers }

    const { twoFactorToken } = useAuth()
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${twoFactorToken.value}`,
      },
    }
  })

  const httpLink = createHttpLink({
    credentials: 'include',
    uri,
  })

  const errorLink = onError((error) => {
    nuxtApp.hook('apollo:error', () => error)
  })

  return from([errorLink, authLink, httpLink])
}
