import type { TypePolicies } from '@apollo/client/cache'

import {
  type LotteryQuery,
  LotteryTicketType,
} from '~/@types/generated/backend/graphql-schema-types'
import { cache } from '~/apollo/local/cache'
import { getInitialConfirmedPlays } from '~/utils/lotteryCache'

export const PersistedStateLottery = {
  LotteryConfirmedPlays: 'lottery_confirmed_plays',
  LotterySelectedAddOns: 'lottery_selected_add_ons',
  LotterySelectedNumbers: 'lottery_selected_numbers',
  LotterySelectedPerPlayAddOns: 'lottery_selected_per_play_add_ons',
  LotterySelectedStakes: 'lottery_stakes',
  LotteryTicketType: 'lottery_ticket_type',
} as const

export type LotteryConfig = LotteryQuery['lottery']['currentConfig']
type Lottery = LotteryQuery['lottery']

export type LotteryConfirmedPlays = Lottery['confirmedPlays']
export type LotterySelectedAddOns = Lottery['selectedAddOns']
export type LotterySelectedPerPlayAddOns = Lottery['selectedPerPlayAddOns']
export type LotterySelectedNumbers = Lottery['selectedNumbers']
export type LotterySelectedStakes = Lottery['selectedStakes']

export type LotteryLocalState = {
  confirmedPlays: LotteryConfirmedPlays
  selectedAddOns: LotterySelectedAddOns
  selectedNumbers: LotterySelectedNumbers
  selectedPerPlayAddOns: LotterySelectedPerPlayAddOns
  selectedStakes: LotterySelectedStakes
  ticketType: LotteryTicketType
}

export const lotteryTypePolicies: TypePolicies = {
  LotteryPayload: {
    fields: {
      confirmedPlays: (_, { readField, variables }) => {
        const config = readField<LotteryConfig>('currentConfig')

        return cache.readCache<LotteryConfirmedPlays>(
          PersistedStateLottery.LotteryConfirmedPlays,
          getInitialConfirmedPlays(config),
          variables,
        )
      },
      selectedAddOns: (_, { readField, variables }) => {
        const config = readField<LotteryConfig>('currentConfig')

        return cache.readCache<LotterySelectedAddOns>(
          PersistedStateLottery.LotterySelectedAddOns,
          getInitialSelectedAddOns(config),
          variables,
        )
      },
      selectedNumbers: (_, { readField, variables }) => {
        const config = readField<LotteryConfig>('currentConfig')

        return cache.readCache(
          PersistedStateLottery.LotterySelectedNumbers,
          getInitialSelectedNumbers(config),
          variables,
        )
      },
      selectedPerPlayAddOns: (_, { readField, variables }) => {
        const config = readField<LotteryConfig>('currentConfig')
        if (!config?.addOns.some(isPerPlay)) return []

        return cache.readCache<LotterySelectedPerPlayAddOns>(
          PersistedStateLottery.LotterySelectedPerPlayAddOns,
          getInitialPerPlayAddOns(config!),
          variables,
        )
      },
      selectedStakes: (_, { readField, variables }) => {
        const config = readField<LotteryConfig>('currentConfig')!

        return cache.readCache(
          PersistedStateLottery.LotterySelectedStakes,
          getInitialStakes(config),
          variables,
        )
      },
      ticketType: (_, { variables }) =>
        cache.readCache<LotteryTicketType>(
          PersistedStateLottery.LotteryTicketType,
          LotteryTicketType.Single,
          variables,
        ),
    },
  },
}
