import type { InMemoryCacheConfig } from '@apollo/client'

import fragmentMatcher from '~/@types/generated/backend/fragmentTypes.json'
import { lotteryTypePolicies } from '~/features/Lottery/apollo/cache'
import { scratchcardTypePolicies } from '~/features/Scratchcard/apollo/cache'
import { verificationTypePolicies } from '~/features/Verification/apollo/cache'

export const inMemoryCacheOptions: InMemoryCacheConfig = {
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies: {
    DrawResultsPayload: {
      keyFields: ['drawDate', 'serialNumber'],
    },
    FixedCharge: {
      keyFields: false,
    },
    ImagePropertiesPayload: {
      keyFields: ['blurHash'],
    },
    PlayerPayload: {
      keyFields: ['email'],
    },
    ...lotteryTypePolicies,
    ...scratchcardTypePolicies,
    ...verificationTypePolicies,
  },
}
