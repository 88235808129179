import type { ApolloQueryResult, FetchResult } from '@apollo/client/core'

import type { RepeatOptions } from '~/apollo/links'

export type VariousResult<T> = ApolloQueryResult<T> | FetchResult<T>

export type RepeatOptionsParams<T> = {
  delay?: number
  errorOnNotFulfilled?: boolean
  fixedDelay?: boolean
  repeatConditions(response: VariousResult<T>): boolean
  repeatCount?: number
}

export const setRepeatOptions = <T>(
  options: RepeatOptionsParams<T>,
): RepeatOptions => ({
  repeatOptions: {
    delay: options.delay,
    errorOnNotFulfilled: options.errorOnNotFulfilled,
    fixedDelay: options.fixedDelay,
    repeatCount: options.repeatCount,
    repeatIf: (response: VariousResult<T>): boolean =>
      (!response.errors?.length ||
        !response.errors.some(
          (err) => err.extensions?.code === 'UNAUTHENTICATED',
        )) &&
      options.repeatConditions(response),
  },
})
