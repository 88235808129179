export enum StateStorageValue {
  CheckedAuth = 'ltc_checked_auth',
}

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return
  const checkedAuth = useState(StateStorageValue.CheckedAuth, () => false)
  if (checkedAuth.value) return
  const { isLoggedIn } = useAuth()
  if (isLoggedIn.value) return

  const checkAuth = () =>
    new Promise((resolve) => {
      try {
        const { mutate } = useRefreshTokenMutation()
        mutate()
          .then((result) => {
            const { accessToken } = result?.data?.refreshToken || {}
            if (!accessToken) {
              resolve(false)
            } else {
              useAuth().accessToken.value = accessToken
              const { onResult } = useUserDataQuery()
              onResult(({ data, loading }) => {
                if (!loading) {
                  // Needed for otherLevels in native app
                  useFlutter()?.setOtherLevelsCustomerId(
                    data?.player?.customerId,
                  )
                  useTracking().createEmailHashEvent()
                }
              })
              resolve(true)
            }
          })
          .catch(resolve)
          .finally(() => {
            checkedAuth.value = true
          })
      } catch (_) {
        resolve(false)
      }
    })
  await checkAuth()
})
