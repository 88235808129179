import revive_payload_client_gkEJd2lnEW from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ATvopLoGip from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_E4os97lwrW from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hszO3XItnq from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.2_vite@5.2.13_@types+node@20.14.15_sass@1._fwpk7th23gszugmcouu5273giy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_uOHxN5XjdA from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Dskx7UtNBv from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GUI3Fl7dLn from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4ttEgb3EWv from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4xSgwDEZ5m from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.19.2_typescript@5.5.4_vue@3.4.35_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/lottocom/shop-frontend/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_gDq8Plcm3a from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt@3.12.4_patch_hash=o6yiren4qrdbw37s2e4mdj6chi_@parcel+watcher@2.4.1_@types+node@20.14.15__4moqxocjadpcl63nsw2hv65nlm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/lottocom/shop-frontend/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_eAroKiXcR7 from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.10.0_magicast@0.3.4_rollup@4.19.2_vite@5.2.13_@types+node@20.14.15_sass@1.77_f672kfzb3a24oow5jwi5jo2kte/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_Dg2MqVOoOn from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/nuxt-csurf@1.6.1_magicast@0.3.4_rollup@4.19.2/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/builds/lottocom/shop-frontend/frontend/.nuxt/unocss.mjs";
import switch_locale_path_ssr_JcBqdxsWg5 from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_ss3K6MWRPH from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_oCGTgF2jXn from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.19.2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_rorJjAX9Za from "/builds/lottocom/shop-frontend/frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.19.2_typesc_64ecjxozu7fbg4so5es6ygzmf4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _10_apollo_Sxw8Ifvyki from "/builds/lottocom/shop-frontend/frontend/plugins/10.apollo.ts";
import frontendComponents_1yhgRn5eVo from "/builds/lottocom/shop-frontend/frontend/plugins/frontendComponents.ts";
import marketing_client_qaYJZ8lssm from "/builds/lottocom/shop-frontend/frontend/plugins/marketing.client.ts";
import sentry_client_shVUlIjFLk from "/builds/lottocom/shop-frontend/frontend/plugins/sentry.client.ts";
import tracking_client_0HR1cQSG9M from "/builds/lottocom/shop-frontend/frontend/plugins/tracking.client.ts";
import yup_OM8D6DMeq7 from "/builds/lottocom/shop-frontend/frontend/plugins/yup.ts";
import zendesk_client_8Sfi4oX528 from "/builds/lottocom/shop-frontend/frontend/plugins/zendesk.client.ts";
export default [
  revive_payload_client_gkEJd2lnEW,
  unhead_ATvopLoGip,
  router_E4os97lwrW,
  _0_siteConfig_hszO3XItnq,
  payload_client_uOHxN5XjdA,
  navigation_repaint_client_Dskx7UtNBv,
  check_outdated_build_client_GUI3Fl7dLn,
  chunk_reload_client_4ttEgb3EWv,
  plugin_vue3_4xSgwDEZ5m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gDq8Plcm3a,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_eAroKiXcR7,
  plugin_Dg2MqVOoOn,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_JcBqdxsWg5,
  i18n_ss3K6MWRPH,
  plugin_oCGTgF2jXn,
  plugin_rorJjAX9Za,
  _10_apollo_Sxw8Ifvyki,
  frontendComponents_1yhgRn5eVo,
  marketing_client_qaYJZ8lssm,
  sentry_client_shVUlIjFLk,
  tracking_client_0HR1cQSG9M,
  yup_OM8D6DMeq7,
  zendesk_client_8Sfi4oX528
]