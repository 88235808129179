<template>
  <PriceBreakdownDetailsWrapper
    v-if="currentPrice"
    v-bind="{ showConvenienceFee }"
    class="mb-none"
    :convenience-fee="prices.convenienceFee"
  >
    <template v-if="isNumberLottery">
      <PriceListPerPlay v-bind="{ numberOfPlays }">
        {{
          $t('lottery.pricebreakdown.priceperplay.numberlottery', {
            amount: prices.playChargePerPlay,
            name: 'line',
          })
        }}
      </PriceListPerPlay>
      <dd>{{ prices.playChargePerPlaySlip }}</dd>
    </template>

    <template
      v-for="(charge, index) in currentPrice.chargesPerPlay"
      v-else-if="isPickLottery"
      :key="index"
    >
      <dt>
        {{
          getPerPlayTranslation(
            lotteryId,
            perPlayAddOn!.name,
            charge.extraInfo!,
          )
        }}
      </dt>
      <dd>{{ formatMoney(charge.baseCharge) }}</dd>
    </template>

    <template v-for="addOn in addOns" :key="addOn.name">
      <PriceListPerPlay v-if="isNumberLottery" v-bind="{ numberOfPlays }">
        {{
          $t('lottery.pricebreakdown.priceperplay.numberlottery', {
            amount: formatMoney(addOn.baseCharge),
            name: $t(`lottery.addon.${addOn.name}`),
          })
        }}
      </PriceListPerPlay>

      <i18n-t
        v-else
        keypath="lottery.pricebreakdown.priceperplay.picklottery"
        scope="global"
        tag="dt"
      >
        <template #addOn>
          {{ $t(`lottery.addon.${addOn.name}`) }}
        </template>
      </i18n-t>

      <dd>
        {{
          formatMoney({
            ...addOn.baseCharge,
            value: addOn.baseCharge.value * numberOfPlays,
          })
        }}
      </dd>
    </template>
  </PriceBreakdownDetailsWrapper>
</template>

<script lang="ts" setup>
import type {
  ChargeForPlay,
  Money as MoneyType,
} from '~/@types/generated/backend/graphql-schema-types'
import Money from '~/models/Money'

type LotteryPriceBreakdownListProps = {
  currentPrice?: Maybe<{
    chargesPerPlay: ChargeForPlay[]
    feePerPlaySlip: MoneyType
    numberOfPlays: number
    playChargePerPlaySlip: MoneyType
  }>
  showConvenienceFee?: boolean
}

const props = defineProps<LotteryPriceBreakdownListProps>()

const { isNumberLottery, isPickLottery, lotteryId, perPlayAddOn } =
  usePlaySlip()
const { getPerPlayTranslation } = useTranslations()

const addOns = computed(
  () => props.currentPrice?.chargesPerPlay[0]?.addOns ?? [],
)
const numberOfPlays = computed(() => props.currentPrice?.numberOfPlays ?? 0)
const prices = computed(() => ({
  convenienceFee: new Money(props.currentPrice?.feePerPlaySlip),
  playChargePerPlay: new Money(
    props.currentPrice?.chargesPerPlay[0]?.baseCharge,
  ),
  playChargePerPlaySlip: new Money(props.currentPrice?.playChargePerPlaySlip),
}))
</script>
