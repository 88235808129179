import { cache } from '~/apollo/local/cache'
import { PersistedState } from '~/apollo/local/types'

const getCachedProductId = (licensedTerritory: string, product: string) =>
  cache.readCache<Maybe<string>>(PersistedState.ProductId, null, {
    licensedTerritory,
    product,
  })

export const useCachedProduct = (productName?: string) => {
  const licensedTerritory = useLicensedTerritory()
  const { currentRoute } = useRouter()

  const params = currentRoute.value.params
  const query = currentRoute.value.query

  const product = ref(
    productName ||
      getLotteryIdInCorrectCase(String(params.lotteryId || query?.p)) ||
      String(params.slug || query?.p),
  )

  const currentProductId = ref(
    getCachedProductId(licensedTerritory, product.value),
  )

  const productId = computed(() => currentProductId.value)

  const deleteProductId = () => {
    cache.deleteCache(PersistedState.ProductId, {
      licensedTerritory,
      product: product.value,
    })
    currentProductId.value = null
  }

  const setProductId = (value: string) => {
    cache.updateCache(PersistedState.ProductId, value, {
      licensedTerritory,
      product: product.value,
    })
    currentProductId.value = value
  }

  watchEffect(() => {
    const newProductId = getCachedProductId(licensedTerritory, product.value)

    if (newProductId === currentProductId.value) return
    currentProductId.value = newProductId
  })

  return {
    deleteProductId,
    product: readonly(product),
    productId,
    setProductId,
  }
}
