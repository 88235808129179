import {
  addMonths,
  differenceInDays,
  differenceInYears,
  formatISO,
  intervalToDuration,
  isAfter,
  isBefore,
  isValid,
  parse,
  parseISO,
} from 'date-fns'

export type DateTimeFormat =
  | 'drawing'
  | 'drawingDay'
  | 'drawingShort'
  | 'drawingTime'
  | 'fullDate'
  | 'fullDateTime'
  | 'fullDateTimeWithSeconds'
  | 'local'
  | 'result'
  | 'resultShort'

type DateTimeFormatOptions = {
  day?: '2-digit' | 'numeric'
  hour?: '2-digit' | 'numeric'
  minute?: '2-digit' | 'numeric'
  month?: '2-digit' | 'long' | 'narrow' | 'numeric' | 'short'
  second?: '2-digit' | 'numeric'
  weekday?: 'long' | 'narrow' | 'short'
  year?: '2-digit' | 'numeric'
}

export const dateTimeFormats: Record<DateTimeFormat, DateTimeFormatOptions> = {
  drawing: {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  },
  drawingDay: {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  },
  drawingShort: {
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'short',
  },
  drawingTime: {
    hour: '2-digit',
    minute: '2-digit',
  },
  fullDate: {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  },
  fullDateTime: {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  fullDateTimeWithSeconds: {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    second: 'numeric',
    year: 'numeric',
  },
  local: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  result: {
    day: 'numeric',
    month: 'short',
    weekday: 'long',
    year: 'numeric',
  },
  resultShort: {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  },
}

export const isoFormat = 'yyyy-MM-dd'

export const ageInYears = (date: string, currentDate = new Date()) =>
  differenceInYears(currentDate, parse(date, isoFormat, currentDate))

export const distance = (first: Date, second: Date) =>
  first.getTime() - second.getTime()

export const duration = (dateNow: Date, dateFuture: Date) =>
  intervalToDuration({
    end: new Date(dateFuture),
    start: new Date(dateNow),
  })

export const formatDate = (
  dateTime: Date | string,
  format: DateTimeFormat,
  timeZone?: string,
) => {
  const date = new Date(dateTime)
  return new Intl.DateTimeFormat('en', {
    ...dateTimeFormats[format],
    timeZone,
  }).format(date)
}

export const formatUTCDateOnly = (value: Maybe<string>) => {
  if (!value) return ''

  const parsedDate = parseDateInUTC(value)
  if (parsedDate) {
    return formatISO(parsedDate, { representation: 'date' })
  }

  return value
}

export const getDaysFromMonth = (month: number, from = new Date()) => {
  const futureDate = addMonths(from, month)
  return differenceInDays(futureDate, from)
}

export const isValidDate = (value?: Maybe<string>) => {
  if (!value) return false

  const parsedDate = parseDate(value)
  return (
    isValid(parsedDate) &&
    isAfter(parsedDate, new Date().setFullYear(1900)) &&
    isBefore(parsedDate, new Date())
  )
}

export const parseDate = (value: string) =>
  parse(value, 'MM/dd/yyyy', new Date())

export const parseDateInUTC = (value?: Maybe<string>) => {
  if (value) {
    if (isValid(parseISO(value))) return parseISO(value)
    if (isValidDate(value)) return parseDate(value)
  }

  return null
}
