import {
  LotteryId,
  type Numbers,
} from '~/@types/generated/backend/graphql-schema-types'
import type { LineItemField } from '~/components/LineItem/LineItem.vue'

export const getLotteryCssVarConstructor = (
  licensedTerritory: string,
  lotteryId: LotteryId,
) => {
  const formattedLicensedTerritory = licensedTerritory.toLowerCase()
  const formattedLotteryId = lotteryId.toLowerCase()

  const createCssVar = (suffix: string, fallback = 'unset') => {
    const lotteryAndLicensedTerritorySpecific = `--${formattedLotteryId}-${formattedLicensedTerritory}-${suffix}`
    const lotterySpecific = `--${formattedLotteryId}-${suffix}`
    return `var(${lotteryAndLicensedTerritorySpecific}, var(${lotterySpecific}, ${fallback}))`
  }

  return { createCssVar }
}

export const getLotteryIdInCorrectCase = (anyCaseLotteryId?: Maybe<string>) =>
  Object.values(LotteryId).find(
    (lotteryId) => lotteryId.toLowerCase() === anyCaseLotteryId?.toLowerCase(),
  ) ?? null

export const getLotteryNumberFields = (numbers: Numbers[]) =>
  numbers.map<LineItemField>((number) => ({
    modifies: number.modifies,
    name: number.name,
    numbers: number.values.map((value) => ({ value })),
  }))
