<template>
  <HyperLink
    v-bind="{ to }"
    class="the-bottom-navigation-item flex flex-col items-center py-xs"
    :class="{ active: isActive }"
  >
    <img
      v-show="!isActive"
      alt=""
      class="icon"
      :height="32"
      :src="iconSrc"
      :width="32"
    />
    <Component :is="animatedIcon" :active="isActive" class="icon" />

    <span class="mt-3xs text-caption text-uppercase font-medium">
      {{ title }}
    </span>
  </HyperLink>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from '#vue-router'

export type TheBottomNavigationItemProps = {
  icon: 'account' | 'my-orders' | 'play-now' | 'results' | 'service'
  title: string
  to: RouteLocationRaw
}

const props = defineProps<TheBottomNavigationItemProps>()

const navIconGlob = import.meta.glob<Record<string, string> | undefined>(
  '~/assets/img/nav/*.svg',
  {
    eager: true,
  },
)
const route = useRoute()

const iconSrc = computed(
  () => navIconGlob[`/assets/img/nav/${props.icon}.svg`]?.default ?? '',
)
const isActive = computed(() =>
  route.matched.some(({ path }) => {
    if (typeof props.to === 'string') return path.includes(props.to)
    if (!props.to.path) return false
    return path.includes(props.to.path)
  }),
)

const animatedIcon = defineAsyncComponent(() => {
  const suffix = toPascalCase(props.icon)
  return import(`./TheBottomNavigationIcon${suffix}.vue`)
})
</script>

<style lang="scss" scoped>
.the-bottom-navigation-item {
  @include hover-state {
    transform: scale(0.95);
  }

  @include focus-state {
    &::before {
      inset: 0.1875rem;
      border-radius: $border-radius-sm;
      box-shadow: 0 0 0 0.1875rem color('info700');
    }
  }

  @include transition-default(color, transform);

  position: relative;
  width: 4.5rem;
  color: color('info800');
  text-decoration: none;

  .icon {
    width: 2rem;
    aspect-ratio: 1;
  }

  &.active {
    @include hover-state {
      transform: none;
    }

    color: color('secondary900');
    cursor: default;
  }

  @include media-mobile-small {
    width: auto;
  }
}
</style>
