import RequestRepeatError from './RequestRepeatError'

export const REPEAT_NOT_FULFILLED_ERROR_MESSAGE =
  'Repeat condition not fulfilled'

export default class RequestRepeatNotFullfilledError extends RequestRepeatError {
  constructor() {
    super(REPEAT_NOT_FULFILLED_ERROR_MESSAGE)
    this.name = 'RequestRepeatNotFullfilled'
  }
}
