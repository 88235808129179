import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ vueApp }) => {
  const {
    application,
    sentry: { dsnClient: dsn, enabled, environment },
    version: release,
  } = useRuntimeConfig().public

  const router = useRouter()

  Sentry.init({
    app: [vueApp],
    attachProps: true,
    attachStacktrace: true,
    autoSessionTracking: false,
    dsn,
    enabled: !process.dev && String(enabled) === 'true',
    environment,
    ignoreErrors: [
      /** SF-4671 - Not fixable on our side **/
      /load.script.error for https:\/\/sst.lotto.com/,
      /** SF-4672 - Not fixable on our side; depends on third party scripts **/
      /Importing a module script failed./,
    ],
    initialScope: {
      tags: {
        application,
        native_app: useCustomContext().isNativeApp ?? false,
      },
    },
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration(),
    ],
    logErrors: process.dev,
    profilesSampleRate: 1.0,
    release,
    replaysSessionSampleRate: 0.1,
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
  })

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
