<template>
  <PriceList v-bind="priceListProps">
    <template v-if="numberOfPlays" #default>
      <LotteryPriceBreakdownList v-bind="{ currentPrice }" />
    </template>
  </PriceList>
</template>

<script lang="ts" setup>
import Money from '~/models/Money'

type LotteryPriceBreakdownProps = {
  showConvenienceFee?: boolean
}

defineProps<LotteryPriceBreakdownProps>()

const { currentPrice } = usePlaySlip()

const numberOfPlays = computed(() => currentPrice.value?.numberOfPlays ?? 0)

const priceListProps = computed(() => ({
  chargeTotal: new Money(currentPrice.value?.chargeTotal),
  convenienceFee: new Money(currentPrice.value?.feePerPlaySlip),
  salesTax: salesTax.value,
  showSalesTax: (salesTax.value.value ?? 0) > 0,
}))

const salesTax = computed(() => new Money(currentPrice.value?.salesTax))
</script>
